<template>
    <Dialog :style="{ width: '450px' }" class="p-fluid" header="Pendências" :closable="false" :modal="true">
        <div class="field">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span style="font-weight: bold">{{ statusAgendamento.descricao }}</span>
            </div>
        </div>
        <div class="field">
            <label for="Resposta">Resolução de pendências: </label>
            <Textarea v-model="respostaStatus" :autoResize="true" rows="3" cols="15"
                :class="{ 'p-invalid': submitted && !statusAgendamento }" />
            <small class="p-error" v-if="submitted && !statusAgendamento">Campo obrigatório.</small>
        </div>
        <template #footer>
            <Button label="Voltar" icon="pi pi-times" class="p-button-outlined p-button-danger"
                @click="$emit('onClose')" />
            <Button label="Finalizar" icon="pi pi-check" class="p-button-outlined p-button-success"
                @click="updateStatusAgendamento" />
        </template>
    </Dialog>
</template>
<script>
import AreaMedicaService from '../../../../services/AreaMedicaService'
import StatusAgendamento from '../../../../enums/StatusAgendamento';
export default {
    props: ['agendamento'],
    data() {
        return {
            statusAgendamento: {},
            respostaStatus: '',
            submitted: false,
            enumStatusAgendamento: StatusAgendamento
        }
    },
    mounted() {
        this.$serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status');

        this.load()
    },
    methods: {
        async load() {
            const path = 'agendamento_status/agendamento'
            const service = new AreaMedicaService(`${path}/${this.agendamento.id}`)
            const response = await service.findAll({})
            this.statusAgendamento = response.data
        },
        async updateStatusAgendamento() {
            if (!this.respostaStatus) {
                this.$toast.add({ severity: 'error', summary: 'Resposta não preenchida, por favor preencha o campo', life: 3000 })
                return
            }

            try {
                await this.$serviceAgendamentoStatus.save({
                    descricao: `Pendência resolvida: ${this.respostaStatus}`,
                    agendamento: this.agendamento,
                    status: { id: this.enumStatusAgendamento.PENDENCIA_PRESTADOR }
                })
                this.$toast.add({ severity: 'success', summary: 'Dado atualizado com sucesso', life: 3000 })
                this.$emit('onClose');
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar dados na tabela', life: 3000 })
            }
        }
    }
}
</script>
<style>
</style>
