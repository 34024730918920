<template>
    <DialogFormAgendamento ref="DialogFormAgendamento" @afterHide="load()" />
    <DialogPreSolicitacoes ref="DialogPreSolicitacoes" @afterHide="load()" />
    <div class="grid step1">
        <div class="col-12">
            <div class="card">
                <Toast />
                <div class="card">
                    <div style="text-align: center" class="mb-2" v-if="$checkPermission('gestao_medica_v3:view')">
                        <a href="javascript:void(0)" @click="$router.replace('/gestao-medica-v3')">
                            Conheça a nova tela de agendamentos com cadastro em etapas
                        </a>
                    </div>
                    <div class="text-800 mb-1">
                        <span v-tooltip="'Listagem de agendamentos'"></span>
                        <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                            <AppInfoManual nomeTelaDoManual="agendamentos-form" />
                            Agendamentos
                            <TourComponent tourName="IndexGuiaGestaoMedica" />
                        </div>
                    </div>
                    <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
                        <div class="p-fluid mt-0 w-full md:mt-1 md:w-5">
                            <Chips class="step2" v-model="filter" placeholder="Pesquisar" @add="loadAppointments" @remove="loadAppointments" />
                        </div>
                        <div class="mt-2 ml-2 md:mt-1">
                            <Button class="step3" v-tooltip.bottom="'Incluir filtro'" icon="pi pi-filter" @click="openDialogFiltro" />
                            <Button
                                v-tooltip.bottom="'Gerar Planilha'"
                                :loading="loadingExcel"
                                class="ml-2 p-button-success step4"
                                icon="pi pi-file-excel"
                                @click="gerarPlanilha()"
                            />
                            <Button
                                v-tooltip.bottom="'Admissões digitais'"
                                :loading="loadingExcel"
                                class="ml-2 p-button"
                                icon="pi pi-file"
                                @click="dialogAdmissoesDigitais()"
                            />
                        </div>
                        <div class="mt-2 ml-auto md:mt-0">
                            <Button
                                v-if="$checkPermission('gestamedica_agendamentos:criar')"
                                class="p-button-outlined mr-2 step7"
                                icon="pi pi-list"
                                label="Pré solicitações"
                                @click="showPreSolicitacoes()"
                            />
                            <Button
                                class="step6"
                                v-if="$checkPermission('gestamedica_agendamentos:criar')"
                                label="Novo agendamento"
                                @click="createAgendamento()"
                            />
                        </div>
                    </div>

                    <DialogFiltro ref="dialogFiltro" @onAplicarFiltros="onAplicarFiltros" />

                    <DialogPreAdmissao
                        v-if="showDialogAdmissaoDigital"
                        v-model:visible="showDialogAdmissaoDigital"
                        @onClose="dialogAdmissoesDigitais"
                    />

                    <DialogTimeline
                        v-if="showTimeline"
                        v-model:agendamento="actionRecord"
                        v-model:visible="showTimeline"
                        @onClose="onClickMenuTimeline"
                    />

                    <AppAnexoDialog
                        v-if="showAnexoDialog"
                        v-model:origemId="actionRecord.id"
                        v-model:visible="showAnexoDialog"
                        origem="AGENDAMENTO"
                        tipo="Outro"
                        title="Anexos para o Agendamento"
                        @onClickCloseAnexo="onClickCloseAnexo"
                        :enableRemove="false"
                    />

                    <DialogEditar
                        v-if="showEditRecordDialog"
                        v-model:visible="showEditRecordDialog"
                        v-bind:form="actionRecord"
                        @onClose="onClickCloseEditRecord"
                        @onSalvar="onClickSalvarEditRecord"
                    />

                    <AppDescricaoDialog
                        v-if="showMotivoDialogAlteracao"
                        v-model:visible="showMotivoDialogAlteracao"
                        title="Descreva a alteração do agendamento"
                        @onClose="onClickCloseMotivoAlteracao"
                        @onConfirm="onClickConfirmMotivoAlteracao"
                    />

                    <AppDescricaoDialog
                        v-if="showMotivoDialogDesistencia"
                        v-model:visible="showMotivoDialogDesistencia"
                        title="Descreva o motivo da desistência"
                        @onClose="onClickCloseMotivoDesistencia"
                        @onConfirm="onClickConfirmMotivoDesistencia"
                    />

                    <AppDescricaoDialog
                        v-if="showMotivoDialogCancelamento"
                        v-model:visible="showMotivoDialogCancelamento"
                        :loadingConfirm="loadingConfirmCancelamento"
                        title="Descreva o motivo do cancelamento"
                        @onClose="onClickCloseMotivoCancelamento"
                        @onConfirm="onClickConfirmMotivoCancelamento"
                    />

                    <MotivoDialog
                        v-if="showMotivoDialog"
                        v-model:agendamento="actionRecord"
                        v-model:visible="showMotivoDialog"
                        @onClose="showMotivoDialog = false"
                    />

                    <PendenciaDialog
                        v-if="showDialogPendencias"
                        v-model:agendamento="actionRecord"
                        v-model:visible="showDialogPendencias"
                        @onClose="onClickClosePendencia"
                    />

                    <DialogFaltaConsulta
                        v-if="dialogFaltaConsulta"
                        v-model:agendamento="actionRecord"
                        v-model:visible="dialogFaltaConsulta"
                        @onClose="onClickCloseDialogFaltaConsulta"
                        @onLoad="load"
                    />

                    <AppConfirmDialog
                        v-if="showConfirmDialog"
                        v-model:visible="showConfirmDialog"
                        :loading="loadingKit"
                        title="Deseja reenviar o kit desse Agendamento para o prestador e solicitante?"
                        @onClose="showConfirmDialog = false"
                        @onConfirm="reenviarKit"
                    />

                    <DialogReservaHorario
                        v-if="showReservaHorariosDialog"
                        v-model:visible="showReservaHorariosDialog"
                        :agendamentoId="actionRecord.id"
                        @onClose="showReservaHorariosDialog = false"
                        @onConfirm="onConfirmReservaHorarios"
                    />

                    <AppLoadingWrapper v-if="loading" />
                    <DataTable
                        v-if="!loading"
                        v-model:expandedRows="expandedRows"
                        :row-hover="true"
                        :value="records"
                        breakpoint="640px"
                        :sortOrder="parseInt(this.sort?.split(' ')?.[1]) || 1"
                        :sortField="this.sort?.split(' ')?.[0] || 'defaultSortField'"
                        class="p-datatable-sm step10"
                        dataKey="id"
                        responsiveLayout="stack"
                        @rowExpand="onRowExpand"
                        @sort="onSort"
                    >
                        <template #empty> Nenhum registro encontrado.</template>
                        <template #loading> Carregando registros. Aguarde ...</template>
                        <Column class="step8" :expander="true" headerStyle="width: 3rem" />
                        <Column field="createdAt" header="Solicitado em" sortable>
                            <template #body="{ data }">
                                <span class="mr-2">
                                    {{ $filters.formatDateOnly(data.createdAt) }}
                                </span>
                                <span>
                                    <Tag
                                        v-if="data.cliente.asoRetido"
                                        :style="{ 'background-color': 'var(--blue-200)', color: 'var(--blue-900)' }"
                                        :value="'Aso retido'"
                                        class="mr-2"
                                    >
                                    </Tag>
                                </span>
                            </template>
                        </Column>
                        <Column field="funcionario.name" header="Funcionário" sortable></Column>
                        <Column field="cliente.name" header="Cliente" sortField="cliente.name"></Column>
                        <Column field="tipoExame.descricao" header="Tipo" sortable></Column>
                        <Column field="status.descricao" header="Situação" sortable>
                            <template #body="{ data }">
                                <div v-if="data.status.id == this.enumStatusAgendamento.PENDENCIA_CLIENTE">
                                    <Tag
                                        v-tooltip="'Clique para visualizar e resolver pendências'"
                                        :style="tagStatusStyleButton(data)"
                                        :value="data.nomeSolicitante ? 'Pendência ' + data.nomeSolicitante : data.status.descricao"
                                        class="mr-2"
                                        @click="onClickExibirPendencias(data)"
                                    >
                                    </Tag>
                                </div>
                                <div v-if="data.status.id == this.enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_AGENDA">
                                    <Button
                                        v-tooltip="'Clique aqui para reservar horários'"
                                        class="p-button-outlined"
                                        label="Clique aqui para reservar horários"
                                        @click="onClickMenuReservaHorarios(data)"
                                    />
                                </div>
                                <div v-if="data.status.id == this.enumStatusAgendamento.DEPOSITO_ANTECIPADO">
                                    <Tag
                                        v-tooltip="'Aguardando depósito antecipado para o prestador'"
                                        :style="tagStatusStyleButton(data)"
                                        :value="data.status.descricao"
                                        class="mr-2"
                                    >
                                    </Tag>
                                </div>

                                <div
                                    v-if="
                                        data.status.id == this.enumStatusAgendamento.DESISTENCIA_DE_VAGA ||
                                        data.status.id == this.enumStatusAgendamento.CANCELADO ||
                                        data.status.id == this.enumStatusAgendamento.PENDENCIA_PRESTADOR
                                    "
                                >
                                    <Tag
                                        v-tooltip="'Clique para visualizar motivo'"
                                        :style="tagStatusStyleButton(data)"
                                        :value="definirDescricaoTagStatus(data)"
                                        class="mr-2"
                                        @click="onClickExibirMotivo(data)"
                                    ></Tag>
                                </div>
                                <div
                                    v-if="
                                        data.status.id != this.enumStatusAgendamento.PENDENCIA_CLIENTE &&
                                        data.status.id != this.enumStatusAgendamento.DESISTENCIA_DE_VAGA &&
                                        data.status.id != this.enumStatusAgendamento.CANCELADO &&
                                        data.status.id != this.enumStatusAgendamento.PENDENCIA_PRESTADOR &&
                                        data.status.id != this.enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_AGENDA &&
                                        data.status.id != this.enumStatusAgendamento.DEPOSITO_ANTECIPADO
                                    "
                                >
                                    <Tag :style="tagStatusStyle(data)" :value="definirDescricaoTagStatus(data)" class="mr-2"></Tag>
                                </div>
                            </template>
                        </Column>
                        <Column bodyClass="text-right" headerStyle="width: 10rem;">
                            <template #body="{ data }">
                                <Button
                                    v-if="data.pcd"
                                    class="p-button-text p-button"
                                    icon="pi pi-cloud-upload"
                                    title="Anexo do enquadramento PCD"
                                    @click="this.onClickMenuAnexos(data)"
                                />
                                <Button
                                    v-if="data.tipoExame.id === this.tipoExame.RETORNO_AO_TRABALHO"
                                    class="p-button-text p-button"
                                    icon="pi pi-cloud-upload"
                                    title="Anexo liberação INSS/Especialista"
                                    @click="this.onClickMenuAnexos(data)"
                                />
                                <Button class="p-button-text p-button step9" icon="pi pi-ellipsis-v" @click="toggleMenu($event, data)" />
                            </template>
                        </Column>
                        <template #expansion="{ data }">
                            <IndexExpansion :hasError="error" :loading="loadingDetalhesAgendamento" :slotProps="data" />
                        </template>
                    </DataTable>

                    <Menu ref="menu" :model="actionItems" :popup="true" />

                    <Paginator
                        v-show="!loading"
                        v-model:rows="perPage"
                        :rowsPerPageOptions="[10, 20, 50]"
                        :totalRecords="total"
                        currentPageReportTemplate="Página {currentPage} de {totalPages}"
                        template="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                        @page="onPage($event)"
                    >
                    </Paginator>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import AreaMedicaService from '../../../../services/AreaMedicaService';
import StatusAgendamento from '../../../../enums/StatusAgendamento';
import PendenciaDialog from './PendenciaDialog';
import DialogReservaHorario from './DialogReservaHorario.vue';
import AppConfirmDialog from '../../../../components/AppConfirmDialog.vue';
import MotivoDialog from './MotivoDialog';
import { getClientAreaMedica } from '../../../../services/http';
import { getTenant, getToken } from '@/common/storage';
import { getIdsAuthorizedCustomers, getIdsAuthorizedCustomersBranches } from '../../../../services/auth';
import { getFilterCustomerIds } from '../../../../services/filters';
import MixinAgendamentoStatusValidator from '../../../agendamento-atendimento/mixins/MixinAgendamentoStatusValidator';
import { getClientBase } from '@/services/http';

import DialogFormAgendamento from '../form/DialogFormAgendamento.vue';
import DialogPreSolicitacoes from './DialogPreSolicitacoes.vue';
import TipoExame from '../../../../enums/TipoExame';
import DialogEditar from './DialogEditar.vue';
import DialogFaltaConsulta from './DialogFaltaConsulta.vue';
import DialogTimeline from './timeline/DialogTimeline.vue';
import IndexExpansion from './IndexExpansion.vue';
import DialogPreAdmissao from './DialogPreAdmissao.vue';
import AppInfoManual from '../../../../components/AppInfoManual.vue';
import TourComponent from '../../../../components/TourComponent';
import DialogFiltro from './DialogFiltro.vue';

export default {
    props: ['filtroStatus'],
    components: {
        IndexExpansion,
        DialogTimeline,
        DialogFaltaConsulta,
        DialogEditar,
        DialogFormAgendamento,
        DialogPreSolicitacoes,
        DialogPreAdmissao,
        DialogReservaHorario,
        PendenciaDialog,
        MotivoDialog,
        AppConfirmDialog,
        AppInfoManual,
        TourComponent,
        DialogFiltro
    },
    emits: ['onClose'],
    data() {
        return {
            loadingDetalhesAgendamento: false,
            error: false,
            records: [],
            page: 1,
            perPage: 10,
            total: null,
            dataFiltro: null,
            loading: false,
            loadingKit: false,
            enumStatusAgendamento: StatusAgendamento,
            filter: [],
            dialogFaltaConsulta: false,
            expandedRows: [],
            actionItems: [],
            actionRecord: {},
            showAnexoDialog: false,
            showConfirmDialog: false,
            showMotivoDialog: false,
            showMotivoDialogAlteracao: false,
            showMotivoDialogDesistencia: false,
            showMotivoDialogCancelamento: false,
            showDialogPendencias: false,
            showEditRecordDialog: false,
            showReservaHorariosDialog: false,
            showTimeline: false,
            filtrosExtras: {},
            loadingExcel: false,
            loadingConfirmCancelamento: false,
            isDiaProximoDaConsulta: false,
            tipoExame: TipoExame,
            showDialogAdmissaoDigital: false,
            sort: '',
            statusOptions: []
        };
    },
    mixins: [MixinAgendamentoStatusValidator],
    async mounted() {
        this.$service = new AreaMedicaService('/agendamentos');
        this.$serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status');
        this.$serviceStatus = new AreaMedicaService('/status_agendamento');
        this.$serviceAgendamentoFuncionario = new AreaMedicaService('/agendamento_funcionario/agendamento');
        await this.loadStatus();
        await this.load(true);
    },
    watch: {
        actionRecord: {
            handler() {
                this.actionItems = [
                    {
                        label: 'Alterar',
                        icon: 'pi pi-pencil',
                        disabled:
                            this.isStatusDesistenciaDeVaga(this.actionRecord) ||
                            this.isStatusCancelado(this.actionRecord) ||
                            this.isStatusNaoConfirmado(this.actionRecord) ||
                            this.isStatusAguardandoAplicacaoRisco(this.actionRecord) ||
                            this.isStatusAguardandoConfComparecimento(this.actionRecord) ||
                            this.isStatusFaltaConsulta(this.actionRecord) ||
                            this.isStatusConcluido(this.actionRecord) ||
                            !this.$checkPermission('gestamedica_agendamentos:editar'),
                        command: () => this.onClickMenuEditar(this.actionRecord)
                    },
                    {
                        label: 'Reenviar Kit',
                        icon: 'pi pi-sync',
                        disabled:
                            (!this.isStatusAgendado(this.actionRecord) && !this.isStatusDiaDaConsulta(this.actionRecord)) ||
                            this.isStatusNaoConfirmado(this.actionRecord) ||
                            this.isStatusCancelado(this.actionRecord) ||
                            this.isStatusConfirmacaoAgenda(this.actionRecord) ||
                            this.isStatusDesistenciaDeVaga(this.actionRecord) ||
                            this.isStatusEmAndamento(this.actionRecord) ||
                            this.isStatusAguardandoAplicacaoRisco(this.actionRecord) ||
                            this.isStatusEmAberto(this.actionRecord) ||
                            this.isStatusKitPendente(this.actionRecord) ||
                            this.isStatusDepositoAntecipado(this.actionRecord) ||
                            this.isStatusPendenciaResolvida(this.actionRecord) ||
                            this.isStatusFaltaConsulta(this.actionRecord) ||
                            this.isStatusPendente(this.actionRecord) ||
                            this.isStatusAguardandoConfComparecimento(this.actionRecord) ||
                            this.isStatusAguardandoConfColaborador(this.actionRecord) ||
                            (this.isStatusPendenciaCliente(this.actionRecord) && !this.$checkPermission('gestamedica_agendamentos:reenviarkit')),
                        command: () => this.onClickMenuKit(this.actionRecord)
                    },
                    {
                        label: 'Desistência de Vaga',
                        icon: 'pi pi-thumbs-down',
                        disabled:
                            !this.isTipoAgendamentoAdmissional(this.actionRecord) || !this.$checkPermission('gestamedica_agendamentos:desistencia'),
                        command: () => this.onClickMenuDesistencia(this.actionRecord)
                    },
                    {
                        label: 'Cancelar',
                        icon: 'pi pi-times',
                        disabled:
                            this.isStatusCancelado(this.actionRecord) ||
                            this.isStatusDiaDaConsulta(this.actionRecord) ||
                            this.isStatusDesistenciaDeVaga(this.actionRecord) ||
                            this.isStatusAguardandoConfComparecimento(this.actionRecord) ||
                            this.isStatusFaltaConsulta(this.actionRecord) ||
                            this.isStatusConcluido(this.actionRecord) ||
                            this.isStatusNaoConfirmado(this.actionRecord) ||
                            !this.$checkPermission('gestamedica_agendamentos:cancelar'),
                        command: () => this.onClickMenuDelete(this.actionRecord)
                    },
                    {
                        label: 'Anexos',
                        icon: 'pi pi-paperclip',
                        disabled: () => !this.$checkPermission('gestamedica_agendamentos:anexos'),
                        command: () => this.onClickMenuAnexos(this.actionRecord)
                    },
                    {
                        label: 'Falta consulta',
                        icon: 'pi pi-times',
                        command: () => this.onClickMenuFaltaConsulta(this.actionRecord),
                        disabled: () =>
                            this.isStatusDesistenciaDeVaga(this.actionRecord) ||
                            this.isStatusCancelado(this.actionRecord) ||
                            this.isStatusNaoConfirmado(this.actionRecord) ||
                            this.isStatusFaltaConsulta(this.actionRecord) ||
                            this.isStatusConcluido(this.actionRecord)
                    },
                    {
                        label: 'Timeline',
                        icon: 'pi pi-sort-amount-down-alt',
                        disabled: () => !this.$checkPermission('gestamedica_agendamentos:timeline'),
                        command: () => this.onClickMenuTimeline()
                    }
                ];
            },
            deep: true
        }
    },
    methods: {
        openDialogFiltro() {
            this.$refs.dialogFiltro.openDialog(this.filtrosExtras.status);
        },
        async createAgendamento() {
            await this.$router.push({ name: 'agendamentos.solicitacao' });
            this.$refs.DialogFormAgendamento.show();
        },
        showPreSolicitacoes() {
            this.$refs.DialogPreSolicitacoes.show();
        },
        async verificarSeEstaProximoDoDiaDaConsulta(agendamento) {
            if (
                agendamento.status.id === this.enumStatusAgendamento?.AGENDADO ||
                agendamento.status.id === this.enumStatusAgendamento?.DIA_DA_CONSULTA
            ) {
                const { data } = await getClientBase().get(`configuracoes-agendamentos-cancelar/validar-dia-proximo-da-consulta/${agendamento.id}`);
                this.isDiaProximoDaConsulta = data;
            } else {
                this.isDiaProximoDaConsulta = false;
            }
        },
        gerarPlanilha() {
            this.loadingExcel = true;

            const idsAuthorizedCustomers = getIdsAuthorizedCustomers();
            const idsAuthorizedBranches = getIdsAuthorizedCustomersBranches();
            const filteredCustomerIds = getFilterCustomerIds();
            const filtrosExtras = {
                status: this.filtrosExtras.status?.map((status) => {
                    return status.id;
                }),
                tipoExame: this.filtrosExtras.tipoExame?.map((tipoExame) => {
                    return tipoExame.id;
                }),
                datas: this.filtrosExtras.datas
            };
            axios({
                url: process.env.VUE_APP_API_BASE_URL + `/agendamentos/gerar-csv/0`,
                params: {
                    filter: this.filter,
                    sort: this.sort,
                    filtrosExtras
                },
                method: 'GET',
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    'x-api-key': getTenant(),
                    'filter-customer': JSON.stringify(filteredCustomerIds),
                    customers: JSON.stringify(idsAuthorizedCustomers),
                    'customer-branches': JSON.stringify(idsAuthorizedBranches)
                }
            }).then((res) => {
                const file = window.URL.createObjectURL(new Blob([res.data]));
                const docUrl = document.createElement('a');
                docUrl.href = file;
                docUrl.setAttribute('download', 'relátorio_agendamentos.xlsx');
                document.body.appendChild(docUrl);
                docUrl.click();
                this.loadingExcel = false;
            });
        },
        async onRowExpand(event) {
            try {
                this.error = false;
                this.loadingDetalhesAgendamento = true;
                const { data } = await this.$serviceAgendamentoFuncionario.findById(event.data.id);
                this.expandedRows.forEach((element) => {
                    if (element.id == data.agendamento.id) {
                        element.unidade = data.unidade;
                        element.setor = data.setor;
                        element.cargo = data.cargo;
                    }
                });
                this.loadingDetalhesAgendamento = false;
            } catch (error) {
                this.loadingDetalhesAgendamento = false;
                this.error = true;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao exibir os detalhes do agendamento!',
                    detail: error.message,
                    life: 3000
                });
            }
        },
        async loadStatus(){
            const {data} = await this.$serviceStatus.findAll({});
            this.statusOptions = data;
        },
        async load(limpaFiltros = false) {
            if (limpaFiltros) {
                this.filter = [];
                await this.aplicarStatusParaFiltragem();
            } else {
                await this.aplicarStatusParaFiltragem(this.filtrosExtras);
            }
        },

        definirDescricaoTagStatus(data) {
            if (data.status.id == StatusAgendamento.PENDENCIA_PRESTADOR) {
                return 'Pendência: ' + data.company.name;
            }
            return data.status.descricao;
        },
        async aplicarStatusParaFiltragem(filtro) {
            const statusIds = filtro?.status?.map((item) => item?.id) ?? [];
            this.filtrosExtras.status = statusIds.length
                ? this.statusOptions.filter((p) => statusIds.includes(p.id))
                : this.statusOptions.filter(
                      (p) =>
                          p.id !== this.enumStatusAgendamento.CANCELADO &&
                          p.id !== this.enumStatusAgendamento.DESISTENCIA_DE_VAGA &&
                          p.id !== this.enumStatusAgendamento.CONCLUIDO &&
                          p.id !== this.enumStatusAgendamento.FALTA_CONSULTA
                  );
            await this.loadAppointments();
        },
        async dialogAdmissoesDigitais() {
            this.showDialogAdmissaoDigital = !this.showDialogAdmissaoDigital;
        },
        async loadAppointments() {
            try {
                this.loading = true;
                this.$refs.dialogFiltro.closeDialog();
                const filtrosExtras = {
                    status: this.filtrosExtras.status?.map((status) => {
                        return status.id;
                    }),
                    tipoExame: this.filtrosExtras.tipoExame?.map((tipoExame) => {
                        return tipoExame.id;
                    }),
                    datas: this.filtrosExtras.datas
                };

                const appointments = await this.$service.findAll({
                    limit: this.perPage,
                    page: this.page,
                    filter: this.filter,
                    filtrosExtras,
                    sort: this.sort
                });

                this.records = appointments.data.items;
                this.total = appointments.data.meta.totalItems;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao carregar informações!', life: 3000 });
            }
        },
        limpar() {
            this.filtrosExtras.datas = null;
        },
        onPage(event) {
            this.page = event.page + 1;
            this.loadAppointments();
        },
        async toggleMenu(event, data) {
            this.actionRecord = data;
            this.$refs.menu.toggle(event);
        },
        tagStatusStyle(data) {
            if (data?.status?.id == StatusAgendamento.PENDENCIA_PRESTADOR) {
                return {
                    'background-color': 'var(--surface-900)',
                    color: 'var(--surface-200)'
                };
            }

            return {
                'background-color': `var(--${data.status.primevueColor})`,
                color: `var(--${this.changeColorTone(data.status.primevueColor, 900)})`
            };
        },
        tagStatusStyleButton(data) {
            return {
                'background-color': `var(--${data.status.primevueColor})`,
                color: `var(--${this.changeColorTone(data.status.primevueColor, 900)})`,
                cursor: 'pointer'
            };
        },
        changeColorTone(primevueColor, newTone) {
            if (!primevueColor) return;
            const [color, tone] = primevueColor.split('-');
            return color + '-' + (newTone || tone);
        },
        onSort(event) {
            this.sort = `${event.sortField} ${event.sortOrder}`;
            this.load();
        },
        async onClickMenuEditar(record) {
            await this.verificarSeEstaProximoDoDiaDaConsulta(record);

            if (typeof this.isDiaProximoDaConsulta === 'number') {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Você não pode alterar esse agendamento!',
                    detail: `O prazo para alterar o agendamento é de até ${this.isDiaProximoDaConsulta}h antes da consulta!`,
                    life: 3000
                });
                return;
            }

            this.actionRecord = record;
            if (
                this.actionRecord.status.id == this.enumStatusAgendamento.EM_ABERTO ||
                this.actionRecord.status.id == this.enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_AGENDA
            ) {
                this.showEditRecordDialog = true;
            } else {
                this.showMotivoDialogAlteracao = true;
            }
        },
        async onClickMenuDesistencia(record) {
            await this.verificarSeEstaProximoDoDiaDaConsulta(record);

            if (typeof this.isDiaProximoDaConsulta === 'number') {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Você não pode desistir do agendamento!',
                    detail: `O prazo para desistir do agendamento é de até ${this.isDiaProximoDaConsulta}h antes da consulta!`,
                    life: 3000
                });
                return;
            }

            this.actionRecord = record;
            this.showMotivoDialogDesistencia = true;
        },
        async onClickMenuDelete(record) {
            this.motivoCancelamento = null;

            await this.verificarSeEstaProximoDoDiaDaConsulta(record);
            if (typeof this.isDiaProximoDaConsulta === 'number') {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Você não pode cancelar esse agendamento!',
                    detail: `O prazo para cancelar o agendamento é de até ${this.isDiaProximoDaConsulta}h antes da consulta!`,
                    life: 3000
                });
                return;
            }

            this.actionRecord = record;
            this.showMotivoDialogCancelamento = true;
        },
        onClickMenuKit(record) {
            this.actionRecord = record;
            this.showConfirmDialog = true;
        },
        onClickMenuReservaHorarios(record) {
            this.actionRecord = record;
            this.showReservaHorariosDialog = true;
        },
        async reenviarKit() {
            try {
                this.loadingKit = true;
                const path = `agendamento/enviar-kit/${this.actionRecord.id}`;
                await getClientAreaMedica().post(path);
                this.loadingKit = false;
                this.showConfirmDialog = false;
                this.$toast.add({
                    severity: 'success',
                    summary: 'O Kit de Agendamento foi enviado com sucesso!',
                    life: 3000
                });
            } catch (error) {
                this.loadingKit = false;
                this.$toast.add({
                    severity: 'error',
                    summary: error.response?.data?.message || error.message,
                    life: 3000
                });
            }
        },
        onClickMenuAnexos(record) {
            this.actionRecord = record;
            this.showAnexoDialog = true;
        },
        onClickMenuFaltaConsulta(record) {
            this.actionRecord = record;
            this.dialogFaltaConsulta = true;
        },
        onClickCloseDialogFaltaConsulta() {
            this.dialogFaltaConsulta = false;
        },
        onClickCloseAnexo() {
            this.showAnexoDialog = false;
        },
        async onClickConfirmMotivoAlteracao(motivo) {
            try {
                const descricao = `Solicitação de alteração: ${motivo}`;
                await this.adicionarStatusAgendamento(this.actionRecord, this.enumStatusAgendamento.PENDENCIA_PRESTADOR, descricao);

                this.showMotivoDialogAlteracao = false;
                this.load();
                this.$toast.add({
                    severity: 'success',
                    summary: 'Processado com sucesso, sua solicitação será analisada.',
                    life: 3000
                });
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: error.response?.data?.message || error.message,
                    life: 3000
                });
            }
        },
        onClickCloseMotivoAlteracao() {
            this.showMotivoDialogAlteracao = false;
        },
        async onClickConfirmMotivoDesistencia(motivo) {
            try {
                const descricao = `Mudança de status para Desistência de Vaga. Motivo: ${motivo}`;
                await this.adicionarStatusAgendamento(this.actionRecord, this.enumStatusAgendamento.DESISTENCIA_DE_VAGA, descricao);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Desistência registrada com sucesso.',
                    life: 3000
                });

                this.showMotivoDialogDesistencia = false;
                this.load();
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: error.response?.data?.message || error.message,
                    life: 3000
                });
            }
        },
        onClickCloseMotivoDesistencia() {
            this.showMotivoDialogDesistencia = false;
        },
        async onClickConfirmMotivoCancelamento(motivo) {
            try {
                this.loadingConfirmCancelamento = true;

                const descricao = `Mudança de status para Cancelado. Motivo: ${motivo}`;
                await this.adicionarStatusAgendamento(this.actionRecord, this.enumStatusAgendamento.CANCELADO, descricao);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Registro cancelado com sucesso.',
                    life: 3000
                });

                this.showMotivoDialogCancelamento = false;
                this.loadingConfirmCancelamento = false;
                this.load();
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: error.response?.data?.message || error.message,
                    life: 3000
                });
                this.loadingConfirmCancelamento = false;
            }
        },
        onClickCloseMotivoCancelamento() {
            this.showMotivoDialogCancelamento = false;
        },
        onClickExibirPendencias(record) {
            this.actionRecord = record;
            this.showDialogPendencias = true;
        },
        onClickExibirMotivo(record) {
            this.actionRecord = record;
            this.showMotivoDialog = true;
        },
        onClickClosePendencia() {
            this.showDialogPendencias = false;
            this.load();
        },
        async adicionarStatusAgendamento(agendamento, idStatus, descricao) {
            await this.$serviceAgendamentoStatus.save({
                descricao,
                agendamento: agendamento,
                status: { id: idStatus },
                observacao: descricao
            });
        },
        onClickCloseEditRecord() {
            this.showEditRecordDialog = false;
        },
        onClickSalvarEditRecord(record) {
            this.actionRecord = { ...this.actionRecord, ...record };
            this.showEditRecordDialog = false;
        },
        onClickMenuTimeline() {
            this.showTimeline = !this.showTimeline;
        },
        onConfirmReservaHorarios() {
            this.showReservaHorariosDialog = false;
            this.load();
        },
        onAplicarFiltros(filtrosExtras) {
            this.filtrosExtras = filtrosExtras;
            this.loadAppointments();
        }
    }
};
</script>
<style lang="scss" scoped>
.status-grid {
    display: grid;
    grid-gap: 0.5rem;
}

.status-item {
    padding: 1rem;
}
.shepherd-step-tooltip {
    max-width: 500px;
}
</style>
