<template>
    <Dialog
        v-model:visible="showDialog"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :modal="true"
        :style="{ width: '80vw' }"
        header="Pré solicitações"
        position="top"
        @hide="hide"
    >
        <Toolbar v-if="!loading" class="mb-4">
            <template #start>
                <Button
                    label="Deletar"
                    icon="pi pi-trash"
                    class="p-button-danger mr-2"
                    @click="confirmMultipleDeletions()"
                    :disabled="!selectedRequests || !selectedRequests.length"
                />
            </template>
        </Toolbar>
        <DataTable
            ref="dt"
            v-model:expandedRows="expandedRows"
            v-model:selection="selectedRequests"
            :filters="filters"
            :paginator="true"
            :rows="10"
            :rowsPerPageOptions="[5, 10, 25]"
            :value="records"
            currentPageReportTemplate="Mostrando {last} de {totalRecords} registros"
            dataKey="id"
            editMode="cell"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            responsiveLayout="scroll"
            @cell-edit-complete="editCell"
        >
            <Column :expander="true" headerStyle="width: 3rem"></Column>
            <Column :exportable="false" selectionMode="multiple" style="flex: 0 0 4rem"></Column>
            <Column :sortable="true" field="nomeFuncionario" header="Funcionario" style="min-width: 12rem"></Column>
            <Column :sortable="true" field="telefoneFuncionario" header="Telefone*" style="min-width: 10rem">
                <template #body="{ data }">
                    <div>
                        <InputMask
                            v-model="data.telefoneFuncionario"
                            :class="{ 'p-invalid mt-4': this.mascaraErro(data) || !data.telefoneFuncionario }"
                            autofocus
                            mask="(99) 99999-9999"
                            @change="saveCampos(data)"
                        />
                    </div>
                    <small v-if="this.mascaraErro(data)" class="p-error">Formato do telefone está errado.</small>
                    <small v-if="!data.telefoneFuncionario" class="p-error">Telefone é Obrigatório.</small>
                </template>
            </Column>
            <Column :sortable="true" field="emailFuncionario" header="Email(Opcional)" style="min-width: 8rem">
                <template #body="{ data, field }">
                    <InputText v-model="data[field]" autofocus @change="saveCampos(data)" />
                </template>
            </Column>
            <Column :sortable="true" field="tipoExame.descricao" header="Tipo de exame" style="min-width: 10rem" />
            <Column :sortable="true" :style="{ padding: '1rem 0.5rem' }" field="data.statusSolicitacao" header="Status">
                <template #body="{ data }">
                    <div v-if="preSolicitacaoTemPendenciaPorStatus(data)" class="field mt-1 mb-0" style="text-align: center">
                        <i
                            v-tooltip="'Campos adicionais obrigatórios'"
                            class="pi pi-exclamation-circle"
                            style="font-size: 2rem; color: #ff9900; cursor: pointer"
                            @click="exibirPendencias(data)"
                        />
                    </div>
                    <Tag v-else :style="getStatusColor(data.statusSolicitacao)" :value="getDescriptionStatus(data.statusSolicitacao)" class="mr-2">
                    </Tag>
                </template>
            </Column>
            <Column field="horariosAgenda" header="Período de agendamento" style="min-width: 12rem">
                <template #body="{ data }">
                    <div v-if="data.statusSolicitacao == 2">
                        {{ $filters.formatDateOnly(data.dataSugestaoInicial) + ' - ' + $filters.formatDateOnly(data.dataSugestaoFinal) }}
                    </div>
                    <div v-if="data.statusSolicitacao == 1">
                        <Dropdown
                            :options="horarios[data.id]"
                            :virtualScrollerOptions="{ loading: horariosLoading[data.id], showLoader: true, itemSize: 30 }"
                            placeholder="Horarios da agenda"
                            @change="onChangeHorario($event, data)"
                            @before-show="carregarHorarios(data)"
                        >
                            <template #value="{ placeholder }">
                                <span v-if="data.dataReserva">
                                    {{ $filters.formatDayMonth(data.dataReserva) }} | {{ data.horarioInicial?.substring(0, 5) }} -
                                    {{ data.horarioFinal?.substring(0, 5) }}
                                </span>
                                <span v-else>
                                    {{ placeholder }}
                                </span>
                            </template>
                            <template #option="{ option }">
                                <span
                                    >{{ $filters.formatDayMonth(data.dataSugestaoInicial) }} | {{ option.horarioInicial }} -
                                    {{ option.horarioFinal }}</span
                                >
                            </template>
                            <template v-slot:loader="{ options }">
                                <div class="flex align-items-center p-2" style="height: 38px">
                                    <Skeleton :width="options.even ? '60%' : '50%'" height="1rem" />
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                </template>
            </Column>
            <template #expansion="{ data }">
                <div class="p-panel p-component">
                    <div class="p-panel-header">
                        <span class="p-panel-title">Detalhes</span>
                    </div>
                    <div class="p-toggleable-content">
                        <div class="p-panel-content">
                            <div class="grid">
                                <div class="col-4">
                                    <div class="field">
                                        <b>Solicitante: </b>
                                        {{ data.nomeSolicitante }}
                                    </div>
                                    <div class="field">
                                        <b>Telefone do solicitante: </b>
                                        {{ $filters.phone(data.telefoneSolicitante) }}
                                    </div>
                                    <div class="field">
                                        <b>Email do solicitante: </b>
                                        {{ data.emailSolicitante }}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="field">
                                        <b>Data de sugestão: </b> entre {{ $filters.formatDateOnly(data.dataSugestaoInicial) }} e
                                        {{ $filters.formatDateOnly(data.dataSugestaoFinal) }} no período da
                                        {{ getDescription(data.periodo) }}
                                    </div>
                                    <div class="field">
                                        <b>Exames do funcionario: </b>
                                        {{ getNomeProcedimento(data.procedimentos) }}
                                    </div>
                                    <div class="field">
                                        <b>Riscos do funcionario: </b>
                                        {{ getRiskname(data.riscos) }}
                                    </div>
                                    <template v-if="data.tipoExame.id == this.EnumTipoExame.DEMISSIONAL">
                                        <div class="field">
                                            <b> Data de demissão: </b>
                                            {{ $filters.formatDateOnly(data.dataHomologacao) }}
                                        </div>
                                    </template>
                                    <template v-if="data.tipoExame.id == this.EnumTipoExame.RETORNO_AO_TRABALHO">
                                        <div class="field">
                                            <b>Data Liberação: </b>
                                            {{ $filters.formatDateOnly(data.dataLiberacao) }}
                                        </div>
                                        <div class="field">
                                            <b>Tipo de retorno: </b>
                                            {{ getDescriptionReturn(data.tipoRetorno) }}
                                        </div>
                                    </template>
                                    <template v-if="data.tipoExame.id == this.EnumTipoExame.CONSULTA">
                                        <div class="field">
                                            <b>Detalhes da consulta</b>
                                            {{ data.detalheConsulta }}
                                        </div>
                                    </template>
                                </div>
                                <div class="col-4">
                                    <div class="field">
                                        <b>Cliente/CNPJ: </b>
                                        {{ data.cliente?.apelido ? data.cliente.apelido : 'Não informado' }} /
                                        {{ data.cliente?.inscricao ? data.cliente.inscricao : 'Não informado' }}
                                    </div>
                                    <div class="field">
                                        <b>Unidade do funcionario/CNPJ: </b>
                                        {{ data.funcionario?.customerHierarchy?.customerBranch?.name ?? 'Não informado' }} /
                                        {{ data.funcionario?.customerHierarchy?.customerBranch?.tradeCode ?? 'Não informado' }}
                                    </div>
                                    <div class="field">
                                        <b>Setor do funcionario: </b>
                                        {{ data.funcionario.customerHierarchy.customerDepartment.name }}
                                    </div>
                                    <div class="field">
                                        <b>Cargo do funcionario: </b>
                                        {{ data.funcionario.customerHierarchy.customerPosition.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div style="text-align: right">
                    <Button :loading ="loading" class="submit p-button-success" icon="pi pi-check" label="Solicitar" @click="iniciarAgendamento()" />
                </div>
            </template>
        </DataTable>
    </Dialog>
    <Dialog v-if="deleteProductsDialog" v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="records">Deseja mesmo deletar as pré-solicitações selecionadas?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-outlined p-button-danger" @click="deleteProductsDialog = false" />
            <Button
                label="Sim"
                icon="pi pi-check"
                class="p-button-outlined p-button-success"
                @click="deleteMultipleRequests(this.selectedRequests)"
            />
        </template>
    </Dialog>
</template>

<script>
import AreaService from '@/services/AreaMedicaService';
import dayjs from 'dayjs';
import { FilterMatchMode } from 'primevue/api';
import { limitarCaracteres, nomeEmpresaUsuarioReduzido } from '@/services/auth';
import TipoExame from '../../../../enums/TipoExame';

export default {
    emits: ['afterHide'],
    data() {
        return {
            showDialog: false,
            loading: false,
            records: null,
            selectedRequests: [],
            deleteProductsDialog: false,
            expandedRows: null,
            filters: null,
            filtrosExtras: {},
            horariosLoading: {},
            horarios: {},
            EnumTipoExame: TipoExame
        };
    },

    mounted() {
        this.$service = new AreaService('/pre-solicitacoes');
        this.initFilters();
    },
    computed: {
        actionItems() {
            const actionItems = [
                { label: 'Alterar', icon: 'pi pi-pencil', command: () => this.editRequest(), visible: true },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    command: () => this.confirmDeletion(this.record),
                    visible: true
                },
                { label: 'Anexos', icon: 'pi pi-paperclip', command: () => this.anexos(this.record), visible: true },
                {
                    label: 'Pendencias',
                    icon: 'pi pi-exclamation-circle',
                    command: () => (this.dialogPendencias = true),
                    visible: this.record?.contevePendencias
                }
            ];

            return actionItems.filter((p) => p.visible);
        }
    },
    methods: {
        show() {
            this.load();
            this.showDialog = true;
        },
        hide() {
            this.showDialog = false;
        },
        confirmMultipleDeletions() {
            this.deleteProductsDialog = true;
        },
        async deleteMultipleRequests() {
            let record = this.selectedRequests;
            for (let i = 0; i < record.length; i++) {
                let id = record[i].id;
                if (id) {
                    try {
                        await this.$service.remove(id);
                        this.records = {};
                        this.deleteProductsDialog = false;
                        this.selectedRequests = null;
                        this.load();
                        this.$toast.add({ severity: 'success', summary: 'Pré-Solicitação excluida com sucesso!', life: 3000 });
                    } catch (err) {
                        this.$toast.add({ severity: 'error', summary: 'Erro ao excluir a Pré-Solicitação!', life: 3000 });
                    }
                }
            }
        },
        preSolicitacaoTemPendenciaPorStatus(agendamento) {
            const idStatus = agendamento.tipoExame.id;
            if (agendamento.pendencia == 'NAO') {
                return false;
            }

            if (agendamento.pcd) {
                return true;
            }

            if (
                idStatus == this.EnumTipoExame.RETORNO_AO_TRABALHO ||
                idStatus == this.EnumTipoExame.CONSULTA ||
                idStatus == this.EnumTipoExame.DEMISSIONAL ||
                idStatus == this.EnumTipoExame.ABONO_ATESTADO
            ) {
                return true;
            }
            if (agendamento.cliente.desabilitarQuestionarioRisco) {
                return false;
            }
            if (agendamento.pendencia == 'SIM') {
                return true;
            }
            if (agendamento.funcionarioSemRiscos) {
                return true;
            }

            return false;
        },
        async iniciarAgendamento() {
            this.loading = true;
            if (!this.selectedRequests?.length) {
                this.$toast.add({ severity: 'error', summary: 'Selecione alguma pré-solicitação!', life: 6000 });
                this.loading = false;
                return;
            }
            const ids = this.selectedRequests.map((p) => p.id);
            this.selectedRequests = this.records.filter((p) => ids.includes(p.id));
            try {
                if (this.validateData()) {
                    this.loading = false;
                    return;
                }
                this.loading = false;
            } catch (err) {
                const message = err?.response?.data?.message || err.message;
                this.$toast.add({ severity: 'error', summary: message, life: 6000 });
                this.loading = false;
            }

            this.saveAppointment(ids);
        },
        async saveAppointment(ids) {
            this.loading = true;
            const service = new AreaService('/pre-solicitacoes/solicitar');
            try {
                await service.save(ids);
                this.$toast.add({ severity: 'success', summary: 'Solicitações realizadas com sucesso!', life: 3000 });
                this.loading = false;
                this.hide();
                this.$emit('afterHide');
            } catch (err) {
                this.loading = false;
                const message = err?.response?.data?.message || err.message;
                this.$toast.add({ severity: 'error', summary: message, life: 6000 });
            }
        },
        validateData() {
            let hasError = false;
            this.selectedRequests.forEach((element) => {
                if (!element.telefoneFuncionario) {
                    this.$toast.add({
                        severity: 'error',
                        summary: `Erro ao salvar solicitação do funcionário ${element.nomeFuncionario}!`,
                        detail: 'Telefone está vazio.',
                        life: 6000
                    });
                    hasError = true;
                } else if (element?.telefoneFuncionario?.length <= 10) {
                    this.$toast.add({
                        severity: 'error',
                        summary: `Erro ao salvar solicitação do funcionário ${element.nomeFuncionario}!`,
                        detail: 'Formato do telefone está errado.',
                        life: 6000
                    });
                    hasError = true;
                }
                if (this.clienteAgendaHorario && !this.newRequestAdd.horarioMarcadoPrestador) {
                    this.$toast.add({
                        severity: 'error',
                        summary: `Erro ao salvar solicitação do funcionário ${element.nomeFuncionario}!`,
                        detail: 'pois não foi informado o horário definido com o prestador',
                        life: 6000
                    });
                    hasError = true;
                }
                if (element?.tipoExame?.id == this.EnumTipoExame.DEMISSIONAL && !element?.dataHomologacao) {
                    this.$toast.add({
                        severity: 'error',
                        summary: `Erro ao salvar solicitação do funcionário ${element.nomeFuncionario}!`,
                        detail: 'Data de demissão é obrigatória para o tipo de exame demissional.',
                        life: 6000
                    });
                    hasError = true;
                    return;
                }
                if (element?.pendencia == 'SIM' && !element?.cliente.desabilitarQuestionarioRisco) {
                    this.$toast.add({
                        severity: 'error',
                        summary: `Erro ao salvar solicitação do funcionário ${element.nomeFuncionario}!`,
                        detail: 'Há pendências de campos obrigatórios não preenchidos.',
                        life: 6000
                    });
                    hasError = true;
                }
                const dataAtual = dayjs();
                const dataAtualSemHoras = dayjs(dataAtual).hour(0).minute(0).second(0).millisecond(0);
                const dataSugestaoFinal = dayjs(element?.dataSugestaoFinal);
                if (dataAtualSemHoras > dataSugestaoFinal) {
                    this.$toast.add({
                        severity: 'error',
                        summary: `Erro ao salvar solicitação do funcionário ${element.nomeFuncionario}!`,
                        detail: 'A data de sugestão é mais antiga que a data atual.',
                        life: 6000
                    });
                    hasError = true;
                }
                if (
                    !element?.horarioMarcadoPrestador &&
                    dataSugestaoFinal.isSame(dataAtualSemHoras) &&
                    element.periodo == 1 &&
                    dataAtual.$d.getHours() > 12
                ) {
                    this.$toast.add({
                        severity: 'error',
                        summary: `Erro ao salvar solicitação do funcionário ${element.nomeFuncionario}!`,
                        detail: 'O periodo é mais antigo que o periodo atual.',
                        life: 6000
                    });
                    hasError = true;
                }

                if (element?.horarioMarcadoPrestador) {
                    const [horaMarcada, minutoMarcado] = element.horarioMarcadoPrestador.split(':');
                    const horaAtualComMinutos = new Date().setHours(parseInt(dataAtual.$d.getHours()), parseInt(dataAtual.$d.getMinutes()), 1, 0);
                    const horarioMarcadoPrestador = new Date().setHours(parseInt(horaMarcada), parseInt(minutoMarcado), 1, 0);

                    if (
                        element?.horarioMarcadoPrestador &&
                        dataSugestaoFinal.isSame(dataAtualSemHoras) &&
                        horaAtualComMinutos >= horarioMarcadoPrestador
                    ) {
                        this.$toast.add({
                            severity: 'error',
                            summary: `Erro ao salvar solicitação do funcionário ${element.nomeFuncionario}!`,
                            detail: 'O horário marcado é mais antigo que o horário atual.',
                            life: 6000
                        });
                        hasError = true;
                    }
                }
            });
            return hasError;
        },
        confirmDeletion(record) {
            this.record = record;
            this.deleteProductDialog = true;
        },
        mascaraErro(data) {
            if (!data.telefoneFuncionario) {
                return;
            }
            if (data.telefoneFuncionario.length < 11) {
                return true;
            }
            return false;
        },
        async editRequest() {
            this.originalValueAvisa = null;
            this.originalValueAgenda = null;
            const { data } = await this.$servicePreRequest.findById(this.record.id);
            this.record = data;
            this.editRangeDatas.push(new Date(this.record.dataSugestaoInicial));
            this.editRangeDatas.push(new Date(this.record.dataSugestaoFinal));
            const responseexames = await this.$baseServiceExams.findAll({ limit: 100 });
            this.exames = responseexames.data.items;
            const responseRiscos = await this.$segurancaRisksService.findAll(null, { limit: 100 });
            this.risco = responseRiscos.data.items;
            this.procedimentosFuncionario = this.record.procedimentos.map((r) => r.procedimento);
            this.clientesSelecionados = [this.record.funcionario.customerId];
            this.record.tipoExame = this.record.tipoExame.id;
            this.record.dataHomologacao = new Date(this.record.dataHomologacao);
            this.record.dataLiberacao = new Date(this.record.dataLiberacao);
            this.editRequestDialog = true;
            const nomeReduzidoEmpresa = await nomeEmpresaUsuarioReduzido(58);
            const nomeReduzidoUsuario = await limitarCaracteres(this.record.nomeSolicitante, 58);

            this.agendaAvisa = [
                { label: nomeReduzidoUsuario, value: this.quemAgendaAvisa.SOLICITANTE },
                { label: nomeReduzidoEmpresa, value: this.quemAgendaAvisa.EMPRESA }
            ];

            const cliente = this.record.cliente;

            if (this.record.prestador?.systemsAndTechnology?.name == 'SOC') {
                this.prestadorReservaHorarios = true;
                this.record.quemAgenda = this.quemAgendaAvisa.SOLICITANTE;
                this.desabilitaQuemAvisa = true;
                this.desabilitaQuemAgenda = true;
                const qtdPermiteAlterarMetodoReservaHorario = cliente.permiteAlterarMetodoReservaHorario == true;
                if (qtdPermiteAlterarMetodoReservaHorario) {
                    this.desabilitaQuemAvisa = false;
                } else {
                    this.record.quemAvisa = this.quemAgendaAvisa.SOLICITANTE;
                    this.desabilitaQuemAvisa = true;
                }
            } else {
                this.verificarQuemAgendaAvisa(this.record.tipoExame);
                this.desabilitaQuemAvisa = false;
                this.desabilitaQuemAgenda = false;
            }

            const usaReservasHorarios = cliente.usaReservaHorario;
            if (this.record.prestador?.systemsAndTechnology?.name == 'SOC' && usaReservasHorarios) {
                this.usaSoc = true;
            }
        },
        async editCell(event) {
            let { data, newValue, field } = event;
            if (data[field] != newValue) {
                if (newValue.trim().length > 0) {
                    data[field] = newValue;
                    data.telefoneColaborador = data.telefoneColaborador
                        ? data.telefoneColaborador.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '')
                        : null;
                    data.telefoneFuncionario = data.telefoneFuncionario
                        ? data.telefoneFuncionario.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '')
                        : null;
                    for (let i = 0; i < this.tipoExame.length; i++) {
                        if (this.tipoExame[i].descricao == data.nomeTipoExame) {
                            data.tipoExame = this.tipoExame[i].id;
                        }
                    }
                    if (data) {
                        try {
                            await this.$service.save(data);
                            data = [];
                            await this.recarregarGrid();
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Dado atualizado com sucesso',
                                life: 3000
                            });
                        } catch (err) {
                            this.$toaso.add({
                                severity: 'success',
                                summary: 'Dado atualizado com sucesso',
                                life: 3000
                            });
                        }
                    }
                } else {
                    event.preventDefault();
                }
            }
        },
        getDescription(periodo) {
            const Periodo = {
                1: 'Manhã',
                2: 'Tarde'
            };
            return Periodo[periodo];
        },
        getStatusColor(status) {
            const Status = {
                1: 'background-color: var(--green-200); color: var(--green-900)',
                2: 'background-color: var(--yellow-200); color: var(--yellow-900)'
            };
            return Status[status];
        },
        async exibirPendencias(solicitacao) {
            const { data } = await this.$service.findById(solicitacao.id);
            this.record = data;
            if (this.record.tipoExame.id == this.EnumTipoExame.MUDANCA_FUNCAO) {
                this.customers = await this.loadCustomers(this.record.funcionario.customerId);
                this.branches = await this.loadBranches(this.customers.id);
                this.departments = await this.loadDepartments(this.customers.id);
                this.positions = await this.loadPositions(this.customers.id);
                this.record.funcionario.changeDate = null;
                this.record.funcionario.customerHierarchy.customerBranchId = null;
                this.record.funcionario.customerHierarchy.customerDepartmentId = null;
                this.record.funcionario.customerHierarchy.customerPositionId = null;
                this.oldHierarchyid = this.record.funcionario.customerHierarchy.id;
            }
            this.pendencias = JSON.parse(JSON.stringify(this.record));
            this.dialogPendencias = true;
        },
        async loadCustomers(id) {
            const { data } = await this.$serviceClients.findById(id);
            return data;
        },
        async loadBranches(id) {
            const { data } = await this.$serviceBranches.findAll({ customerId: id });
            return data;
        },
        async loadDepartments(id) {
            const { data } = await this.$serviceDepartments.findAll({ customerId: id });
            return data;
        },
        async loadPositions(id) {
            const { data } = await this.$servicePositions.findAll({ customerId: id });
            return data;
        },
        getRiskname(riscos) {
            let nomeRiscos = '';
            for (let i = 0; i < riscos.length; i++) {
                if (nomeRiscos) {
                    nomeRiscos = nomeRiscos + ', ' + riscos[i]?.nome;
                } else {
                    nomeRiscos = riscos[i]?.nome;
                }
            }
            return nomeRiscos;
        },
        getNomeProcedimento(procedimentosList) {
            const procedimentos = procedimentosList.map((r) => r.procedimento);
            let nomeProcedimento = '';
            for (let i = 0; i < procedimentos.length; i++) {
                if (nomeProcedimento) {
                    nomeProcedimento = nomeProcedimento + ', ' + procedimentos[i]?.name;
                } else {
                    nomeProcedimento = procedimentos[i]?.name;
                }
            }
            return nomeProcedimento;
        },
        async carregarHorarios(preRequest) {
            try {
                this.horariosLoading[preRequest.id] = true;
                const { data } = await this.$serviceAgendaHorarios.findAll({
                    idsProcedures: 1,
                    dataInicial: preRequest.dataSugestaoInicial,
                    dataFinal: preRequest.dataSugestaoFinal
                });
                this.horarios[preRequest.id] = data.horarios;
            } finally {
                this.horariosLoading[preRequest.id] = false;
            }
        },
        getDescriptionStatus(status) {
            const Status = {
                1: 'Pré-agendado',
                2: 'Pré-solicitado'
            };
            return Status[status];
        },
        async onChangeHorario(event, preRequest) {
            await this.$service.save({
                id: preRequest.id,
                dataReserva: event.value.dataReserva,
                horarioInicial: event.value.horarioInicial,
                horarioFinal: event.value.horarioFinal
            });
            preRequest.dataReserva = event.value.dataReserva;
            preRequest.horarioInicial = event.value.horarioInicial;
            preRequest.horarioFinal = event.value.horarioFinal;
        },
        async saveCampos(data) {
            try {
                await this.$service.save(data);
                data = [];
                await this.recarregarGrid();
                this.$toast.add({ severity: 'success', summary: 'Dado atualizado com sucesso', life: 3000 });
            } catch (err) {
                this.$toaso.add({ severity: 'success', summary: 'Dado atualizado com sucesso', life: 3000 });
            }
        },
        async recarregarGrid() {
            const { data } = await this.$service.findAll({ limit: this.perPage, page: this.page, filter: this.filter });
            this.records = data;
            this.total = data.total;
            const calculoPaginacao = data.total / this.perPage;
            this.totalPage = calculoPaginacao === Math.floor(calculoPaginacao) ? calculoPaginacao : Math.floor(calculoPaginacao) + 1;
            const preSolicitacoesQuantidade = data.map((preSolicitacao) => preSolicitacao);
            const user = data.map((user) => user);
            if (preSolicitacoesQuantidade.length && user[0].user.exibePreSolicitacaoNotificacao === true) {
                this.showModalAvisoPreSolicitacoes = true;
            }
        },
        async load() {
            this.loading = true;
            await this.recarregarGrid();
            // await this.carregarTipoExame();
            // await this.defineQuemAgendaAvisa();
            this.loading = false;
        },
        // async defineQuemAgendaAvisa() {
        //     if (this.profile?.quemAgenda && this.profile?.quemAvisa) {
        //         this.quemAgenda = this.profile?.quemAgenda;
        //         this.quemAvisa = this.profile?.quemAvisa;
        //     }
        //     const nomeReduzidoEmpresa = await nomeEmpresaUsuarioReduzido(58);
        //     const nomeReduzidoUsuario = await nomeUsuarioReduzido(58);
        //     this.agendaAvisa = [
        //         { label: nomeReduzidoUsuario, value: this.quemAgendaAvisa.SOLICITANTE },
        //         { label: nomeReduzidoEmpresa, value: this.quemAgendaAvisa.EMPRESA }
        //     ];
        // },
        toggleMenu(event, data) {
            this.record.id = data?.id;
            this.$refs.menu.toggle(event);
        },
        getDescriptionReturn(tipoRetorno) {
            const TipoRetorno = {
                1: 'B31 - Auxilio - Doença Previdenciário',
                2: 'B91 - Auxilio - Doença por acidente do trabalho',
                3: 'B94 - Auxilio - Acidente por acidente do trabalho',
                4: 'Licença Maternidade',
                5: 'Prestação do Serviço Militar'
            };
            return TipoRetorno[tipoRetorno];
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS }
            };
        }
    }
};
</script>

<style scoped></style>
