<template>
    <div>
        <AppLoadingWrapper v-if="loading"/>
        <Timeline v-else :value="agendamentoStatus" class="customized-timeline">
            <template #marker="slotProps">
                <span :style="{ backgroundColor: slotProps.item.hexColor }" class="custom-marker shadow-2">
                    <i :class="slotProps.item.iconName"></i>
                </span>
            </template>
            <template #opposite="slotProps">
                <div class="flex flex-column">
                    <small class="p-text-secondary">{{ $filters.formatDate(slotProps.item.data) }}</small>
                    <small class="p-text-secondary">{{ slotProps.item.name }}</small>
                </div>
            </template>
            <template #content="slotProps">
                <div class="flex flex-column">
                    <div class="mb-1">{{ slotProps.item.descricao }}</div>
                    <small class="p-text-secondary mb-3">{{ slotProps.item.observacao }}</small>
                </div>
            </template>
        </Timeline>
    </div>
</template>
<script>
import BaseService from '../../../../../../services/BaseService';

export default {
    props: {
        agendamentoId: {
            type: Number
        },
    },
    data() {
        return {
            active: 0,
            agendamentoStatus: [],
            loading: false,
        };
    },
    created() {
        this.load();
    },
    methods: {
        async load() {
            this.loading = true;
            const service = new BaseService(`/timeline/externa/${this.agendamentoId}`);
            const { data } = await service.findAll({});
            this.agendamentoStatus = data;
            this.loading = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}
</style>
