<template>
    <Dialog visible :style="{ width: '800px' }" header="Admissão digital" class="p-fluid" :closable="true" :modal="true">
        <AppPaginatedGrid
        ref="grid"
        :service="service"
        :expander="false"
        :tipo="tipoFiltroEnum.SISTEMA"
        :modulo="moduloFiltroEnum.ADMISSAO_DIGITAL"
        descricao="Admissão digital"
        :showActionItems="false"
        nomeTelaDoManual="admissao-digital-list"
    >
        <template #columns>
            <Column field="id" header="Solicitado em">
                <template #body="slotProps">
                    {{ $filters.formatDateOnly(slotProps.data.createdAt) }}
                </template>
            </Column>
            <Column field="funcionario.name" header="Funcionário"></Column>
            <Column field="cpf" header="CPF">
                <template #body="slotProps">
                    {{ mCPF(slotProps.data.cpf) }}
                </template>
            </Column>
            <Column field="id" header="Tipo">
                <template #body="slotProps">
                    {{ slotProps.data.tipoExame?.descricao }}
                </template>
            </Column>
            <Column field="etapa" header="etapa">
                <template #body="slotProps">
                     {{ enumStatusPreAdmissaoLabel.get(slotProps.data.etapa) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
    </Dialog>
</template>

<script>
import { EnumStatusPreAdmissaoLabel } from '../../../../enums/EnumStatusPreAdmissao';
import ModuloFiltroEnum from '../../../../enums/ModuloFiltroEnum';
import TipoFiltroEnum from '../../../../enums/TipoFiltroEnum';
import BaseService from '@/services/BaseService';
import { mCPF } from '../../../../utils/Mask';
export default {
    data() {
        return {
            record: [],
            page: 1,
            service: null,
            total: 0,
            loading: false,
            totalPage: 0,
            perPage: 10,
            recordDialog: false,
            deleteRecordDialog: false,
            filter: [],
            enumStatusPreAdmissaoLabel: EnumStatusPreAdmissaoLabel,
            mCPF
        };
    },
    mounted() {
        this.service = new BaseService('/agendamento-pre-admissao');
    },
    computed: {
        moduloFiltroEnum() {
            return ModuloFiltroEnum;
        },
        tipoFiltroEnum() {
            return TipoFiltroEnum;
        }
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        }
    }
};
</script>

