<template>
    <Dialog
        :breakpoints="{ '1000px': '60vw', '650px': '80vw' }"
        :style="{ width: '60vw' }"
        class="p-fluid"
        :modal="true"
        header="Reserva de horários"
        :closable="!loadingConfirmar"
    >
        <div v-if="loadingConfirmar" class="div-loading">
            <ProgressBar mode="indeterminate" style="height: 0.3em" />
            <Message severity="info">Aguarde estamos inserindo os agendamentos no prestador. Esta operação pode demorar. Aguarde.</Message>
        </div>
        <div v-if="loadingBuscandoHorarios" class="div-loading">
            <ProgressBar mode="indeterminate" style="height: 0.3em" />
            <Message severity="info">Aguarde. Estamos buscando os melhores horários disponíveis para a data selecionada.</Message>
        </div>
        <div class="div-content" :style="loadingConfirmar || loadingBuscandoHorarios ? 'opacity: 30%;' : ''">
            <AppLoadingWrapper v-if="loading" />
            <div v-for="(grupo, indexGrupo) in recordsAgrupados" :key="indexGrupo" class="p-panel-content">
                <Fieldset :legend="grupo.title" class="mb-4">
                    <div class="grid">
                        <div class="col-2" v-if="!grupo.prestadorInformado"><strong>Exame particular</strong></div>
                        <div class="col-3"><strong>Prestador</strong></div>
                        <div class="col-3"><strong>Exames</strong></div>
                        <div class="col-2"><strong>Dias de antecedência</strong></div>
                        <div :class="!grupo.prestadorInformado ? 'col-2' : 'col-4'"><strong>Data</strong></div>
                    </div>
                    <div v-for="(item, index) in grupo.items" :key="index" class="p-panel-content">
                        <div class="grid">
                            <div v-if="!item.prestadorInformado" class="col-2">
                                <div class="field-checkbox">
                                    <InputSwitch id="binary" v-model="item.exameParticular" />
                                    <label for="binary">Particular</label>
                                </div>
                            </div>
                            <div class="col-3">
                                <DropdownPrestadorCliente
                                    v-model="item.prestador"
                                    :idCliente="agendamento.cliente.id"
                                    :idCidade="agendamento.cidade.id"
                                    :idExame="getIdsExames(item)"
                                    :disabled="item.prestadorInformado || item.exameParticular"
                                    :autoLoad="!item.prestadorInformado && !item.exameParticular"
                                />
                            </div>
                            <div class="col-3">
                                {{ item.nomesExames }}
                            </div>
                            <div class="col-2 mt-3">
                                {{ item.diasAntecedencia }}
                            </div>
                            <div :class="!item.prestadorInformado ? 'col-2' : 'col-4'">
                                <Dropdown
                                    v-if="item.prestadorInformado"
                                    placeholder="Horarios da agenda"
                                    v-model="item.horario"
                                    :options="horariosDisponiveis[item.nomesExames]"
                                    @change="changeHorario(item)"
                                    @before-show="carregarSeNecessario(item)"
                                    :loading="loadingHorarios[item.nomesExames]"
                                    :disabled="!podePreencherHorario(item)"
                                >
                                    <template #value="{ placeholder, value }">
                                        <span v-if="value && value.horarioChegada">
                                            {{ $filters.formatDayMonth(value.horarioChegada) }} | Entrada {{ formatTimeUtc(value.horarioChegada) }} -
                                            Saída {{ formatTimeUtc(value.horarioSaida) }} ({{ value.minutosAgendas }}
                                            min)
                                        </span>
                                        <span v-else>
                                            {{ placeholder }}
                                        </span>
                                    </template>
                                    <template #option="{ option }">
                                        <span
                                            >{{ $filters.formatDayMonth(option.horarioChegada) }} | Entrada
                                            {{ formatTimeUtc(option.horarioChegada) }} - Saída {{ formatTimeUtc(option.horarioSaida) }} ({{
                                                option.minutosAgendas
                                            }}
                                            min)
                                        </span>
                                    </template>
                                </Dropdown>
                                <small v-if="loadingHorarios[item.nomesExames]" class="p-info">Aguarde buscando horários no prestador.</small>
                                <Calendar
                                    v-if="!item.prestadorInformado"
                                    v-model="item.horario"
                                    dateFormat="dd/mm/yy"
                                    selectionMode="single"
                                    :showIcon="true"
                                />
                            </div>
                        </div>
                    </div>
                    <Message v-if="grupo.info" severity="info"> {{ grupo.info }}</Message>
                </Fieldset>
            </div>
        </div>
        <template #footer>
            <div>
                <Button
                    :disabled="loadingConfirmar"
                    label="Cancelar"
                    icon="pi pi-times"
                    class="p-button-outlined p-button-danger"
                    @click="onClickClose"
                />
                <Button
                    label="Confirmar"
                    :loading="loadingConfirmar"
                    icon="pi pi-check"
                    class="p-button-outlined p-button-success"
                    @click="onClickConfirmar"
                />
            </div>
        </template>
    </Dialog>
</template>
<script>
import { getClientBase } from '@/services/http';
import DropdownPrestadorCliente from '@/pages/prestadores/components/DropdownPrestadorCliente';
import AreaMedicaService from '@/services/AreaMedicaService';
import * as moment from 'moment-timezone';
import { flatten } from 'ramda';

export default {
    name: 'ReservaHorariosDialog',
    components: { DropdownPrestadorCliente },
    emits: ['onConfirm', 'onClose'],
    props: {
        agendamentoId: {
            type: Number,
            required: true
        }
    },
    async mounted() {
        await this.loadData();
    },
    data() {
        return {
            loading: false,
            loadingConfirmar: false,
            loadingBuscandoHorarios: false,
            agendamento: null,
            records: [],
            horariosDisponiveis: {},
            loadingHorarios: {},
            recordsAgrupados: [],
            idsExames: {},
            exames: []
        };
    },
    methods: {
        formatTimeUtc(hora) {
            return moment.tz(hora, 'UTC').format('HH:mm');
        },
        buscarHorariosPorExame() {
            return [];
        },
        async loadData() {
            try {
                this.loading = true;
                const serviceAgendamentos = new AreaMedicaService('agendamentos');
                const [responseReserva, responseAgendamento] = await Promise.all([
                    getClientBase().get('agendamentos/reservas-horarios/' + this.agendamentoId),
                    serviceAgendamentos.findById(this.agendamentoId)
                ]);
                this.records = responseReserva.data;
                this.recordsAgrupados = [
                    {
                        prestadorInformado: true,
                        title: 'Exames com prestador informado',
                        items: this.records.filter((p) => p.prestadorInformado)
                    },
                    {
                        prestadorInformado: false,
                        title: 'Exames sem prestador informado',
                        info: 'Procedimentos devem ser agendados diretamente com o prestador informado.',
                        items: this.records.filter((p) => !p.prestadorInformado)
                    }
                ].filter((p) => p.items.length);
                const examesAgrupados = this.recordsAgrupados.map((p) => {
                    if (!p.prestadorInformado) {
                        return p.items.map((q) => q.exames.map((r) => r));
                    }
                });
                this.exames = flatten(examesAgrupados);
                this.exames = this.exames.filter((p) => p?.id);
                this.agendamento = responseAgendamento.data;
                this.records.forEach((p) => {
                    this.horariosDisponiveis[p.nomesExames] = [];
                });
                this.carregarTodosHorarios();
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$toast.add({
                    severity: 'error',
                    summary: error?.response?.data?.message || error?.message,
                    life: 3000
                });
            }
        },
        async carregarTodosHorarios() {
            for (const record of this.records) {
                if (this.isPrimeiro(record)) {
                    await this.carregarHorarios(record);
                } else {
                    const anterior = this.getAnterior(record);
                    if (anterior.horario && record.prestadorInformado) {
                        await this.carregarHorarios(record);
                    }
                }
            }
        },
        async carregarSeNecessario(record) {
            if (this.loadingHorarios[record.nomesExames]) {
                return;
            }
            await this.carregarHorarios(record);
        },
        async carregarHorarios(record) {
            try {
                const horarios = this.horariosDisponiveis[record.nomesExames];
                if (horarios && horarios.length) {
                    return;
                }
                const dto = {
                    prestadorId: this.agendamento.idPrestador,
                    funcionarioId: this.agendamento.idFuncionario,
                    ambienteIds: record.ambientes.map((p) => p.id),
                    examesIds: record.exames.map((p) => p.id),
                    dataInicial: this.agendamento.dataSugestaoInicial,
                    dataFinal: this.agendamento.dataSugestaoFinal,
                    periodo: this.agendamento.periodo
                };
                if (!this.isPrimeiro(record)) {
                    const anterior = this.getAnterior(record);
                    const dataInicialMoment = moment(anterior.horario.horarioChegada).add(anterior.diasAntecedencia, 'd');
                    const dataInicial = dataInicialMoment.toDate();
                    const dataFinal = dataInicialMoment.add(3, 'd');
                    dto.dataInicial = dataInicial;
                    dto.dataFinal = dataFinal;
                }
                this.loadingHorarios[record.nomesExames] = true;
                this.loadingBuscandoHorarios = true;
                const { data } = await getClientBase().post('agendamentos/faixas-disponiveis', dto);
                this.horariosDisponiveis[record.nomesExames] = data;
                this.loadingHorarios[record.nomesExames] = false;
                this.loadingBuscandoHorarios = false;
                if (!data?.length) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Sem horários disponíveis para a data/período informado. Tente informar outra data de sugestão.',
                        life: 3000
                    });
                }
            } catch (error) {
                this.loadingHorarios[record.nomesExames] = false;
                this.loadingBuscandoHorarios = false;
                this.$toast.add({
                    severity: 'error',
                    summary: error?.response?.data?.message || error?.message,
                    life: 3000
                });
            }
        },
        isPrimeiro(record) {
            const index = this.records.indexOf(record);
            return index <= 0;
        },
        getAnterior(record) {
            const index = this.records.indexOf(record);
            return this.records[index - 1];
        },
        podePreencherHorario(record) {
            const isPrimeiro = this.isPrimeiro(record);
            if (isPrimeiro) {
                return true;
            }
            const anterior = this.getAnterior(record);
            return !!anterior.horario;
        },
        async changeHorario(record) {
            this.limparLinhasAbaixo(record);
            await this.carregarTodosHorarios();
        },
        limparLinhasAbaixo(record) {
            const posicao = this.records.indexOf(record);
            for (let index = posicao + 1; index < this.records.length; index++) {
                this.records[index].horario = null;
                this.horariosDisponiveis[this.records[index].nomesExames] = [];
            }
        },
        getIdsExames(record) {
            const found = this.idsExames[record.nomesExames];
            if (!found) {
                this.idsExames[record.nomesExames] = record.exames?.map((p) => p.id) ?? [];
            }
            return this.idsExames[record.nomesExames];
        },
        async onClickConfirmar() {
            try {
                this.loadingConfirmar = true;
                const lista = this.records.map((record) => {
                    return {
                        prestadorId: record.prestador?.id,
                        examesIds: record.exames.map((q) => q.id),
                        diasAntecedencia: record.diasAntecedencia,
                        prestadorInformado: record.prestadorInformado,
                        horarioSemPrestador: !record.prestadorInformado ? record.horario : null,
                        itens: record.horario?.itens,
                        exameParticular: record.exameParticular
                    };
                });
                await getClientBase().post('agendamentos/reservas-horarios/' + this.agendamentoId, lista);
                this.loadingConfirmar = false;
                await this.$emit('onConfirm');
            } catch (error) {
                this.loadingConfirmar = false;
                this.$toast.add({
                    severity: 'error',
                    summary: error?.response?.data?.message || error?.message,
                    life: 3000
                });
            }
        },
        onClickClose() {
            this.$emit('onClose');
        }
    }
};
</script>
<style>
.div-loading {
    margin-top: -40px;
    margin-left: 200px;
    position: absolute;
    z-index: 9999;
}
</style>
