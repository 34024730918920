<template>
    <Dialog
        v-model:visible="dialogFaltaConsulta"
        :closable="false"
        :modal="true"
        :style="{ width: '400px' }"
        class="p-fluid"
        header="Pré-solicitação"
    >
        <div>
            <span>Deseja alterar o status para falta consulta?</span>
        </div>
        <template #footer>
            <Button class="p-button-outlined p-button-danger" icon="pi pi-times" label="Cancelar" @click="onClickClose" />
            <Button
                :disabled="loadingSave"
                :loading="loadingSave"
                class="p-button-outlined p-button-success"
                icon="pi pi-check"
                label="Salvar"
                @click="onClickAtualizar"
            />
        </template>
    </Dialog>
</template>
<script>
import StatusAgendamento from '@/enums/StatusAgendamento';
import BaseService from '@/services/BaseService';

export default {
    emits: ['onClose', 'onLoad'],
    props: ['agendamento'],
    mounted() {
        this.$serviceAgendamentoStatus = new BaseService('/agendamento_status');
        this.$serviceCliente = new BaseService('customers');
        this.getCliente();
    },
    data() {
        return {
            loadingSave: false,
            cliente: {}
        };
    },
    methods: {
        async onClickAtualizar() {
            try {
                this.loadingSave = true;
                await this.$serviceAgendamentoStatus.save({
                    descricao: `Pendência resolvida: ${this.respostaStatus}`,
                    agendamento: this.agendamento,
                    status: { id: StatusAgendamento.FALTA_CONSULTA }
                });
                this.loadingSave = false;
                this.$emit('onLoad');
                this.$emit('onClose');
                if (this.cliente.limiteReagendamento && this.agendamento.quantidadeFaltaConsulta >= this.cliente.limiteReagendamento) {
                    this.$toast.add({
                        severity: 'success',
                        summary: `Registro atualizado com sucesso, o limite de
                        reagendamentos por falta consulta deste cliente foi
                        atingido, o mesmo não será reagendado novamente`,
                        life: 3000
                    });
                } else {
                    this.$toast.add({ severity: 'success', summary: 'Registro atualizado com sucesso', life: 3000 });
                }
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Erro ao atualizar o agendamento!', life: 3000 });
            }
        },
        async getCliente() {
            const { data } = await this.$serviceCliente.findById(this.agendamento.idCliente);
            this.cliente = data;
        },
        onClickClose() {
            this.$emit('onClose');
        }
    }
};
</script>
