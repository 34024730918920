<template>
    <Dialog
        v-model:visible="showDialog"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :modal="true"
        :style="{ width: '80vw' }"
        header="Criar agendamento"
        position="top"
        @hide="hide"
    >
        <div>
            <Toast />

            <Steps :activeStep="passoAtual" :model="items" aria-label="Form Steps" />

            <router-view
                v-slot="{ Component }"
                :formData="formObject"
                @nextPage="nextPage($event)"
                @prevPage="prevPage($event)"
                @saveAgendamento="debounceSalvaAgendamento"
                @savePreSolicitacao="savePreSolicitacao()"
            >
                <keep-alive>
                    <component :is="Component" :isLoading="isLoading" />
                </keep-alive>
            </router-view>
        </div>
    </Dialog>
</template>

<script>
import PreSolicitacaoService from '../services/PreSolicitacaoService';
import AgendamentoService from '../services/AgendamentoService';
import TipoExame from '../../../../enums/TipoExame';
import eventBus from '../../../../event-bus';
import store from '../../../../store';

export default {
    data() {
        return {
            isLoading: false,
            showDialog: false,
            formObject: {},
            EnumTipoExame: TipoExame,
            $agendamentoService: new AgendamentoService({})
        };
    },
    mounted() {
        this.$preSolicitacaoService = new PreSolicitacaoService(this.$toast);
        this.$agendamentoService = new AgendamentoService(this.$toast);
        eventBus.on('onOpenDialogFormAgendamento', () => {
            this.showDialog = true;
        });
    },
    computed: {
        prestadorUsaSoc() {
            return this.formObject.prestador?.systemsAndTechnology?.name === 'SOC';
        },
        possuiRisco() {
            return this.formObject.funcionarios?.some((funcionario) => funcionario.possuiRisco);
        },
        validarClienteUsaReservaHorario() {
            const { formObject, EnumTipoExame } = this;
            const tipoExameId = formObject.tipoExame?.id;

            return (
                formObject?.cliente?.usaReservaHorario &&
                ![EnumTipoExame.CONSULTA, EnumTipoExame.RETORNO_AO_TRABALHO, EnumTipoExame.TERCEIROS].includes(tipoExameId)
            );
        },
        items() {
            const items = [
                { label: 'Dados do agendamento', to: '/gestao-medica/agendamentos/solicitacao' },
                { label: 'Definir clínica', to: '/gestao-medica/agendamentos/prestador' },
                { label: 'Procedimentos', to: '/gestao-medica/agendamentos/exames-procedimentos' }
            ];

            if (this.prestadorUsaSoc && this.possuiRisco && this.validarClienteUsaReservaHorario) {
                items.push({
                    label: 'Reserva de horário',
                    to: '/gestao-medica/agendamentos/procedimentos'
                });
            }

            items.push({ label: 'Solicitações', to: '/gestao-medica/agendamentos/solicitacoes' });

            return items;
        },
        passoAtual() {
            return this.items.findIndex((item) => item.to === this.$route.path);
        }
    },
    methods: {
        show() {
            this.showDialog = true;
        },
        hide() {
            this.$router.push({ name: 'agendamentos' });
        },
        async nextPage(event) {
            this.isLoading = true;
            if (event.formData) {
                this.formObject = event.formData;
            }

            for (let field in event.formData) {
                this.formObject[field] = event.formData[field];
            }

            let to = event.pageIndex + 1;
            await this.$router.push(this.items[to].to);
            this.isLoading = false;
        },
        prevPage(event) {
            this.isLoading = true;

            for (let field in event.formData) {
                this.formObject[field] = event.formData[field];
            }

            let to = event.pageIndex - 1;

            if ((!this.prestadorUsaSoc || !this.possuiRisco) && to === 3) {
                to -= 1;
            }

            if (!this.formObject.examesEmDia && (to === 2 || to === 3)) {
                to -= 1;
            }

            this.$router.push(this.items[to].to);

            this.isLoading = false;
        },
        async savePreSolicitacao() {
            this.isLoading = true;
            let success = true;

            for (let i = 0; i < this.formObject.funcionarios.length; i++) {
                const funcionario = this.formObject.funcionarios[i];
                funcionario.procedimentosDtoCopia = funcionario.procedimentos;

                const anexosLaudos = funcionario.anexoLaudo;
                const anexosPCD = funcionario.anexosPCD;

                if (anexosLaudos && anexosLaudos.length) {
                    funcionario.anexos = funcionario.anexos ? [...funcionario.anexos, ...anexosLaudos] : [...anexosLaudos];
                }

                if (anexosPCD && anexosPCD.length) {
                    funcionario.anexos = funcionario.anexos ? [...funcionario.anexos, ...anexosPCD] : [...anexosPCD];
                }

                const result = await this.$preSolicitacaoService.save(this.formObject, funcionario);
                if (!result) {
                    success = false;
                }
            }

            this.isLoading = false;

            if (success) {
                this.showDialog = false;
                this.formObject = {};
            }
        },

        async debounceSalvaAgendamento() {
            try {
                const is_calling = await store.dispatch('tenant/callWithDebouncer', this.saveAgendamento);
                if (!is_calling) {
                    throw new Error('store indisponível');
                }
            } catch (err) {
                console.error(err);
                await this.saveAgendamento();
            }
        },

        async saveAgendamento() {
            this.isLoading = true;
            let success = true;

            for (const funcionario of this.formObject.funcionarios) {
                if (this.formObject.examesEmDia) {
                    this.verificaExameClinico(funcionario);
                    this.removerExamesRejeitados(funcionario);
                }

                const anexosLaudos = funcionario.anexoLaudo;
                const anexosPCD = funcionario.anexosPCD;

                if (anexosLaudos?.length) {
                    funcionario.anexos = funcionario.anexos ? [...funcionario.anexos, ...anexosLaudos] : [...anexosLaudos];
                }

                if (anexosPCD?.length) {
                    funcionario.anexos = funcionario.anexos ? [...funcionario.anexos, ...anexosPCD] : [...anexosPCD];
                }

                const result = await this.$agendamentoService.save(this.formObject, funcionario);
                if (!result) {
                    success = false;
                }
            }

            this.isLoading = false;

            if (success) {
                this.showDialog = false;
                this.formObject = {};
            }
        },

        removerExamesRejeitados(funcionario) {
            const examesId = funcionario.exames.map((el) => el.procedimento.id);
            const examesAceitarId = funcionario.exames?.filter((el) => el.aceitar).map((el) => el.procedimento.id);
            const procedimentosAdicionados = funcionario.procedimentos.filter((el) => !examesId.includes(el.id));
            funcionario.procedimentos = funcionario.procedimentos?.filter((el) => examesAceitarId.includes(el.id));
            funcionario.procedimentos.push(...procedimentosAdicionados);
        },
        verificaExameClinico(funcionario) {
            funcionario.exames.find((el) => el.procedimento.clinical).aceitar = true;
        }
    }
};
</script>

<style scoped></style>
