<template>
    <div class="p-panel p-component">
        <div :style="panelHeaderStyle(slotProps)" class="p-panel-header">
            <span class="p-panel-title">Detalhes</span>
        </div>
        <div class="p-toggleable-content">
            <AppLoadingWrapper v-if="loading && loadingDadosProcedimentos" style="height: 200" />
            <div v-if="!loading" class="p-panel-content">
                <div class="grid">
                    <div class="col-5">
                        <div class="field">
                            <b>Unidade: </b>
                            {{ slotProps?.unidade?.name ? slotProps.unidade.name : 'Não informado' }}
                        </div>
                        <div class="field">
                            <b>Setor: </b>
                            {{ slotProps?.setor?.name ? slotProps.setor.name : 'Não informado' }}
                        </div>
                        <div class="field">
                            <b>Cargo: </b>
                            {{ slotProps?.cargo?.name ? slotProps.cargo.name : 'Não informado' }}
                        </div>
                        <div class="field">
                            <b>Telefone do Funcionário: </b>
                            {{ $filters.phone(slotProps.telefoneFuncionario) }}
                        </div>
                    </div>
                    <div class="col-7">
                        <div v-if="slotProps.observacao" class="field">
                            <b>Observação: </b>
                            {{ slotProps.observacao }}
                        </div>

                        <div class="field">
                            <b>Data de sugestão: </b> entre {{ $filters.formatDateOnly(slotProps.dataSugestaoInicial) }} e
                            {{ $filters.formatDateOnly(slotProps.dataSugestaoFinal) }} no periodo
                            {{ getLabelPeriodo(slotProps.periodo).label }}
                        </div>
                        <div class="field">
                            <b>Riscos: </b>
                            {{ !dadosRiscos?.length ? 'Agendamento sem riscos' : dadosRiscos }}
                        </div>
                        <div class="field">
                            <b>Cliente/CNPJ: </b>
                            {{ slotProps?.cliente.name ?? 'Nome não informado' }} /
                            {{ slotProps?.cliente.inscricao == '' ? 'CNPJ não informado' : slotProps?.cliente.inscricao }}
                        </div>
                        <div class="field">
                            <b>Unidade/CNPJ: </b>
                            {{ slotProps?.unidade.name ?? 'Não informado' }} /
                            {{ slotProps?.unidade.tradeCode ?? 'CNPJ não informado' }}
                        </div>
                    </div>

                    <template v-if="dadosProcedimento">
                        <Divider align="center"> </Divider>
                        <DataTable
                            :value="dadosProcedimento"
                            dataKey="dadosProcedimento.id"
                            responsiveLayout="scroll"
                            style="width: 100%; padding: 1%"
                        >
                            <Column field="procedimentos.name" header="Procedimento"></Column>
                            <Column header="Data Agendamento">
                                <template #body="{ data }">
                                    {{ data.dataAgendamento ? $filters.formatDate(data.dataAgendamento) : 'Em processo de agendamento' }}
                                </template>
                            </Column>
                            <Column header="Prestador">
                                <template #body="{ data }">
                                    {{ data.prestador ? data.prestador.name : 'Em processo de agendamento' }}
                                </template>
                            </Column>
                            <Column header="Endereço do Prestador">
                                <template #body="{ data }">
                                    {{ !data.prestador ? 'Em processo de agendamento' : '' }}
                                    {{ data.prestador?.street ? 'Rua: ' + data.prestador.street : '' }}
                                    {{ data.prestador?.number ? ', ' + data.prestador.number : '' }}
                                    {{ data.prestador?.neighborhood ? ' Bairro: ' + data.prestador.neighborhood : '' }}
                                    <br />
                                    {{ data.prestador?.addressComplement ? ' Complemento: ' + data.prestador.addressComplement : '' }}
                                    {{ data.prestador?.zipCode ? ' CEP: ' + data.prestador?.zipCode : '' }}
                                    {{
                                        data.prestador?.city?.cidade && data.prestador?.city?.estado
                                            ? 'Cidade: ' + data.prestador.city.cidade + ' - ' + data.prestador?.city?.estado
                                            : ''
                                    }}
                                </template>
                            </Column>
                            <Column header="Recomendações">
                                <template #body="{ data }">
                                    {{ data.recommendation }}
                                </template>
                            </Column>
                        </DataTable>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import StatusAgendamento from '@/enums/StatusAgendamento';
import BaseService from '@/services/BaseService';
import { getClientBase } from '@/services/http';

export default {
    props: {
        slotProps: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean
        },
        hasError: {
            type: Boolean
        }
    },
    data() {
        return {
            periodos: [
                { label: 'Manhã', value: 1 },
                { label: 'Tarde', value: 2 }
            ],
            enumStatusAgendamento: StatusAgendamento,
            loadingDadosProcedimentos: false,
            dadosProcedimento: null,
            dadosRiscos: null
        };
    },
    mounted() {
        // this.$serviceDadosProcedimento = new BaseService('/agendamento_procedimento/agendamento');
        this.$serviceDadosRiscos = new BaseService('/agendamento-riscos/agendamento');
        this.loadProcedimentos(this.slotProps);
        this.loadRiscos(this.slotProps);
    },
    methods: {
        async loadProcedimentos(record) {
            this.dadosProcedimento = null;
            this.loadingDadosProcedimentos = true;

            // const { data } = await this.$serviceDadosProcedimento.findById(record.id);
            const { data } = await getClientBase().get(`/agendamento_procedimento/agendamento/${record.id}`, {
                params: { origem: 'NOVAGESTAOMEDICA_AGENDAMENTOS_INDEX_INDEXEXPANSION_LOADPROCEDIMENTOS' }
            });
            if (!data) return;
            this.dadosProcedimento = data;
        },
        async loadRiscos(record) {
            this.dadosRiscos = null;
            const { data } = await this.$serviceDadosRiscos.findById(record.id);
            if (!data) return;
            this.dadosRiscos = data;
            if (this.dadosRiscos.length) {
                this.dadosRiscos = this.dadosRiscos.map((r) => r.risco.nome).toString();
            }
        },
        panelHeaderStyle(data) {
            return {
                'background-color': `var(--${this.changeColorTone(data.status.primevueColor, 100)})`,
                color: `var(--${this.changeColorTone(data.status.primevueColor, 900)})`
            };
        },
        getLabelPeriodo(value) {
            const periodo = this.periodos.find((p) => p.value === value);
            return periodo || { label: 'Não informado' };
        },
        changeColorTone(primevueColor, newTone) {
            if (!primevueColor) return;
            const [color, tone] = primevueColor.split('-');
            return color + '-' + (newTone || tone);
        }
    }
};
</script>
