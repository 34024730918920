<template>
    <Dialog :closable="false" :modal="true" :style="{ width: '900px' }" class="p-fluid" header="Atualizar dados">
        <AppLoadingWrapper v-if="loading" />
        <div v-if="!loading">
            <div class="field">
                <label>Dados do solicitante</label>
                <div class="formgrid grid">
                    <div class="field col">
                        <InputText
                            id="nomeSolicitante"
                            v-model.trim="formLocal.nomeSolicitante"
                            :class="{ 'p-invalid': v$.formLocal.nomeSolicitante.$invalid }"
                            autofocus
                            placeholder="Nome"
                            required="true"
                        />
                        <small v-if="v$.formLocal.nomeSolicitante.$invalid" class="p-error">O nome do solicitante é obrigatório.</small>
                    </div>
                    <div class="field col">
                        <InputText
                            id="emailSolicitante"
                            v-model.trim="formLocal.emailSolicitante"
                            :class="{ 'p-invalid': v$.formLocal.emailSolicitante.$invalid }"
                            autofocus
                            placeholder="Email"
                            required="true"
                        />
                        <small v-if="v$.formLocal.emailSolicitante.$invalid" class="p-error">O email do solicitante é obrigatório.</small>
                    </div>
                    <div class="field col">
                        <InputMask
                            id="telefoneSolicitante"
                            v-model.trim="formLocal.telefoneSolicitante"
                            :class="{ 'p-invalid': v$.formLocal.telefoneSolicitante.$invalid }"
                            autofocus
                            mask="(99) 99999-9999"
                            placeholder="Telefone"
                            required="true"
                        />
                        <small v-if="v$.formLocal.telefoneSolicitante.$invalid" class="p-error">O telefone do solicitante é obrigatório.</small>
                    </div>
                </div>
            </div>
            <div class="field">
                <label class="mb-3" for="id">Tipo de Exame</label>
                <Dropdown
                    id="id"
                    v-model="formLocal.tipoExame.id"
                    :options="records.tipoExames"
                    optionLabel="descricao"
                    optionValue="id"
                    placeholder="Tipo de exame"
                ></Dropdown>
            </div>
            <template v-if="formLocal.tipoExame.id && formLocal.tipoExame.id == 3">
                <div class="field">
                    <label for="anexo">Anexo abono de atestado</label>
                    <FileUpload
                        v-model="formLocal.anexoAbono"
                        :auto="true"
                        :class="{ 'p-invalid': submitted && !formLocal.anexoAbono }"
                        :customUpload="true"
                        :fileLimit="1"
                        :maxFileSize="10485760"
                        :showCancelButton="false"
                        :showUploadButton="false"
                        chooseLabel="Escolha o arquivo"
                        invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb."
                        mode="advanced"
                        name="anexoLaudo"
                        @uploader="myUploader"
                    >
                        <template #empty>
                            <p>Arraste e solte o arquivo aqui.</p>
                        </template>
                    </FileUpload>
                    <small v-if="submitted && !formLocal.anexoAbono" class="p-error">Campo obrigatório.</small>
                </div>
            </template>
            <template v-if="formLocal.tipoExame.id && formLocal.tipoExame.id == 5">
                <div class="field">
                    <label>Nova Unidade</label>
                    <InputText placeholder="Unidade" />
                </div>
                <div class="field">
                    <label>Novo setor</label>
                    <InputText placeholder="Setor" />
                </div>
                <div class="field">
                    <label>Novo cargo</label>
                    <InputText placeholder="Cargo" />
                </div>
            </template>
            <div v-if="formLocal.tipoExame.id && formLocal.tipoExame.id == 4" class="field">
                <label>Data de Demissão</label>
                <Calendar
                    id="dataHomologacao"
                    v-model="formLocal.dataHomologacao"
                    :class="{ 'p-invalid': submitted && !formLocal.dataHomologacao }"
                    :showIcon="true"
                    dateFormat="dd/mm/yy"
                    selectionMode="single"
                />
                <small v-if="submitted && !formLocal.dataHomologacao" class="p-error">Campo obrigatório para demissional.</small>
            </div>
            <template v-if="formLocal.tipoExame.id && formLocal.tipoExame.id == 9">
                <div class="field">
                    <label for="motivoConsulta">Motivo da Consulta</label>
                    <Dropdown
                        id="motivoConsulta"
                        v-model="formLocal.motivoConsulta"
                        :class="{ 'p-invalid': submitted && !formLocal.motivoConsulta }"
                        :options="motivosConsulta"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Selecione um motivo"
                    ></Dropdown>
                    <small v-if="submitted && !formLocal.motivoConsulta" class="p-error">Campo obrigatório para consultas.</small>
                </div>
                <div class="field">
                    <label for="detalheConsulta">Detalhes da Consulta</label>
                    <Textarea
                        id="detalheConsulta"
                        v-model="formLocal.detalheConsulta"
                        :class="{ 'p-invalid': submitted && !formLocal.detalheConsulta }"
                        cols="20"
                        required="true"
                        rows="3"
                    />
                    <small v-if="submitted && !formLocal.detalheConsulta" class="p-error">Campo obrigatório para consultas.</small>
                </div>
                <div class="field">
                    <label for="anexo">Anexo</label>
                    <FileUpload
                        v-model="formLocal.anexoConsulta"
                        :auto="true"
                        :class="{ 'p-invalid': submitted && !formLocal.anexoConsulta }"
                        :customUpload="true"
                        :fileLimit="1"
                        :maxFileSize="10485760"
                        :showCancelButton="false"
                        :showUploadButton="false"
                        chooseLabel="Escolha o arquivo"
                        invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb."
                        mode="advanced"
                        name="anexoLaudo"
                        @uploader="myUploader"
                    >
                        <template #empty>
                            <p>Arraste e solte o arquivo aqui.</p>
                        </template>
                    </FileUpload>
                    <small v-if="submitted && !formLocal.anexoConsulta" class="p-error">Campo obrigatório.</small>
                </div>
            </template>
            <template v-if="formLocal.tipoExame.id && formLocal.tipoExame.id == 3">
                <div class="field">
                    <div class="field">
                        <label for="tipoRetorno">Tipo de Retorno</label>
                        <Dropdown
                            id="tipoRetorno"
                            v-model="formLocal.tipoRetorno"
                            :class="{ 'p-invalid': submitted && !formLocal.tipoRetorno }"
                            :options="tiposRetorno"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Escolha o Tipo de Retorno"
                        ></Dropdown>
                        <small v-if="submitted && !formLocal.tipoRetorno" class="p-error">Campo obrigatório para Retorno ao Trabalho.</small>
                    </div>
                    <div class="field">
                        <label>Data Liberação</label>
                        <Calendar
                            id="dataLiberacao"
                            v-model="formLocal.dataLiberacao"
                            :class="{ 'p-invalid': submitted && !formLocal.dataLiberacao }"
                            :showIcon="true"
                            dateFormat="dd/mm/yy"
                            selectionMode="single"
                        />
                        <small v-if="submitted && !formLocal.dataLiberacao" class="p-error">Campo obrigatório para Retorno ao Trabalho.</small>
                    </div>
                </div>
            </template>
            <div class="grid formgrid">
                <div class="field col">
                    <label for="cidades">Cidade</label>
                    <DropdownCidade id="cidade" v-model="formLocal.cidade" :class="{ 'p-invalid': v$.formLocal.cidade.$invalid }" />
                    <small v-if="v$.formLocal.cidade.$invalid" class="p-error">A cidade é obrigatória.</small>
                </div>
                <div class="field col">
                    <label for="funcionario">Funcionario</label>
                    <InputText
                        id="funcionario"
                        v-model.trim="formLocal.funcionario.name"
                        :class="{ 'p-invalid': submitted && !formLocal.funcionario }"
                        disabled="true"
                    />
                    <small v-if="submitted && !formLocal.funcionario.name" class="p-error">O nome do funcionário é obrigatório.</small>
                </div>
                <div class="field col">
                    <label for="prestador">Prestador</label>
                    <DropdownPrestadorCliente
                        v-model="formLocal.prestador"
                        :autoLoad="true"
                        :class="{ 'p-invalid': v$.formLocal.prestador.$invalid }"
                        :disabled="!formLocal.cidade"
                        :idCidade="cidadeId"
                        :idCliente="clientesSelecionados"
                    />
                    <small v-if="v$.formLocal.prestador.$invalid" class="p-error">O prestador é obrigatório.</small>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="editRangeDatas">Datas de sugestão</label>
                    <AppInputCalendarioRanged
                        id="editRangeDatas"
                        ref="inputCalendario"
                        v-model="editRangeDatas"
                        :idCidade="formLocal.cidade?.id"
                        :idPrestador="formLocal.prestador?.id"
                        :periodo="formLocal.periodo"
                        :required="true"
                        :submitted="submitted"
                        @onGetPeriodoManhaVisible="onGetPeriodoManhaVisible"
                    />
                </div>
                <div class="field col">
                    <label for="periodo">Periodo</label>
                    <Dropdown
                        id="periodo"
                        v-model="formLocal.periodo"
                        :options="periodo"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Selecione..."
                    ></Dropdown>
                </div>
            </div>
            <div class="field">
                <label for="observação">Observação</label>
                <Textarea id="observação" v-model="formLocal.observacao" :autoResize="true" cols="20" rows="3" />
            </div>
            <div class="field-checkbox">
                <InputSwitch id="binary" v-model="formLocal.resideForaDoPais" />
                <label for="binary">Funcionario reside fora do país</label>
            </div>
            <div class="field-checkbox">
                <InputSwitch id="pcd" v-model="formLocal.pcd" />
                <label for="pcd">Enquadramento PCD</label>
            </div>
        </div>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label=" " @click="$emit('onClose')" />
            <Button class="p-button-outlined p-button-success" icon="pi pi-check" label="Atualizar" @click="onClickSalvar" />
        </template>
    </Dialog>
</template>
<script>
import DropdownCidade from '../../../cidades/components/DropdownCidade.vue';
import DropdownPrestadorCliente from '../../../prestadores/components/DropdownPrestadorCliente.vue';
import UploadService from '../../../../services/upload-file';
import AreaMedicaService from '../../../../services/AreaMedicaService';
import BaseService from '../../../../services/BaseService';
import moment from 'moment-timezone';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    components: {
        DropdownCidade,
        DropdownPrestadorCliente
    },
    props: ['form'],
    model: {
        prop: 'form',
        event: 'formchange'
    },
    async mounted() {
        this.$service = new AreaMedicaService('/agendamentos');
        this.$uploadService = new UploadService('/pre-solicitacoes/upload');
        this.$serviceTipoExame = new AreaMedicaService('/tipo_exames');
        this.$serviceCidade = new BaseService('/cidades');
        this.$serviceDadosFuncionario = new AreaMedicaService('/agendamento_funcionario/agendamento');
        this.$serviceDadosPrestador = new AreaMedicaService('/agendamento_prestador/agendamento');
        this.clientesSelecionados = [this.formLocal.funcionario.customerId];
        this.cidadeId = this.formLocal?.cidade?.id ? this.formLocal.cidade.id : null;

        this.load();
    },
    setup() {
        return {
            v$: useVuelidate()
        };
    },
    validations() {
        return {
            formLocal: {
                nomeSolicitante: { required, $lazy: true },
                emailSolicitante: { required, $lazy: true },
                telefoneSolicitante: { required, $lazy: true },
                cidade: { required, $lazy: true },
                prestador: { required, $lazy: true }
            }
        };
    },

    data() {
        return {
            submitted: false,
            loading: false,
            cidadeId: null,
            clientesSelecionados: [],
            records: {},
            editRangeDatas: [],
            arrayPrestadores: [],
            perPage: 0,
            periodoManhaAtivo: true,
            motivosConsulta: [
                { label: 'Motivo 1', value: 1 },
                { label: 'Motivo 2', value: 2 },
                { label: 'Motivo 3', value: 3 },
                { label: 'Motivo 4', value: 4 }
            ],
            tiposRetorno: [
                { label: 'B31 - Auxilio - Doença Previdenciário', value: 1 },
                { label: 'B91 - Auxilio - Doença por acidente do trabalho', value: 2 },
                { label: 'B94 - Auxilio - Acidente por acidente do trabalho', value: 3 },
                { label: 'Licença Maternidade', value: 4 },
                { label: 'Prestação do Serviço Militar', value: 5 }
            ]
        };
    },
    computed: {
        formLocal: {
            get: function () {
                return this.form || {};
            },
            set: function (value) {
                this.$emit('formchange', value);
            }
        },
        periodo() {
            const prestadorUsaSoc = this.formLocal.prestador?.systemsAndTechnology?.name == 'SOC';
            const periodo = [
                { label: 'Manhã', value: 1, visible: this.periodoManhaAtivo },
                { label: 'Tarde', value: 2, visible: true },
                { label: 'Ambos', value: 3, visible: !prestadorUsaSoc }
            ];
            return periodo.filter((p) => p.visible == true);
        }
    },
    watch: {
        'formLocal.cidade'() {
            this.cidadeId = this.formLocal?.cidade?.id ? this.formLocal.cidade.id : null;
            this.formLocal.prestador = null;
        }
    },
    methods: {
        onGetPeriodoManhaVisible(value) {
            this.periodoManhaAtivo = value;
        },
        async myUploader(event) {
            let formData = new FormData();
            formData.append('file', event.files[0]);
            try {
                const { data } = await this.$uploadService.upload(formData);
                this.formLocal.anexoLaudo = data.path;
                this.$toast.add({ severity: 'success', summary: 'Arquivo carregado com sucesso', life: 3000 });
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao carregar o arquivo', life: 3000 });
            }
        },
        buscaPrestadorLiberado(prestador) {
            let color = '';
            if (this.arrayPrestadores.indexOf(prestador) != -1) {
                color = '#000000';
                return color;
            } else {
                color = '#A9A9A9';
                return color;
            }
        },
        async onClickSalvar() {
            this.submitted = true;
            const formularioCorreto = await this.v$.$validate();
            if (!formularioCorreto) return;
            this.formLocal.dataSugestaoInicial = this.editRangeDatas[0];
            this.formLocal.dataSugestaoFinal = this.editRangeDatas[1];
            this.formLocal.telefoneColaborador = this.formLocal.telefoneColaborador
                ? this.formLocal.telefoneColaborador.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '')
                : null;
            this.formLocal.telefoneSolicitante = this.formLocal.telefoneSolicitante
                ? this.formLocal.telefoneSolicitante.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '')
                : null;
            if (this.formLocal.periodo.value) {
                this.formLocal.periodo = this.formLocal.periodo.value;
            }
            if (this.formLocal.tipoRetorno) {
                this.formLocal.tipoRetorno = this.formLocal.tipoRetorno.value;
            }
            if (this.formLocal) {
                try {
                    await this.$service.save(this.formLocal);
                    this.$emit('onSalvar', this.formLocal);
                    this.$toast.add({ severity: 'success', summary: 'Registro atualizado com sucesso!', life: 3000 });
                } catch (err) {
                    this.$toast.add({ severity: 'error', summary: 'Falha ao atualizar registro', life: 3000 });
                }
            }
        },
        async load() {
            this.loading = true;
            this.formLocal.funcionario = {};
            this.formLocal.prestador = {};
            this.records.tipoExames = [];
            this.records.cidades = [];

            await this.carregarOpcoesDropdown();

            const agendamentoFuncionario = await this.$serviceDadosFuncionario.findById(this.formLocal.id);
            const agendamentoPrestador = await this.$serviceDadosPrestador.findById(this.formLocal.id);
            this.formLocal.funcionario = agendamentoFuncionario.data.funcionario;
            this.formLocal.prestador = agendamentoPrestador.data.prestador;

            const dataSugestaoInicial = moment.tz(this.formLocal.dataSugestaoInicial, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate();
            const dataSugestaoFinal = moment.tz(this.formLocal.dataSugestaoFinal, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate();

            this.editRangeDatas = [dataSugestaoInicial, dataSugestaoFinal];

            if (this.formLocal.motivoConsulta && this.formLocal.tipoExame.id == 9) {
                const motivoConsulta = this.motivosConsulta.find((item) => item.value == parseInt(this.formLocal.motivoConsulta));
                this.formLocal.motivoConsulta = motivoConsulta.value;
            }
            if (this.formLocal.tipoExame.id == 3) {
                if (this.formLocal.tipoRetorno) {
                    this.formLocal.tipoRetorno = parseInt(this.formLocal.tipoRetorno);
                }
                if (this.formLocal.dataLiberacao) {
                    this.formLocal.dataLiberacao = new Date(this.formLocal.dataLiberacao);
                }
            }
            this.orderedSupplier(this.formLocal.funcionario.customerId, this.formLocal.cidade.id);
            this.arrayPrestadores = [];
            const path = 'prestador-clientes/client';
            const service = new AreaMedicaService(`${path}/${this.formLocal.funcionario.customerId}`);
            const response = await service.findAll({});
            for (const prestadores of response.data) {
                this.arrayPrestadores.push(prestadores?.prestador?.id);
            }
            this.loading = false;
        },
        async carregarOpcoesDropdown() {
            const tipoExames = await this.$serviceTipoExame.findAll({});
            this.records.tipoExames = tipoExames.data;
            const { data } = await this.$serviceCidade.findAll({
                limit: this.perPage,
                page: this.page,
                filter: this.filter
            });
            data.items.forEach((item) => (item.cidade = `${item.cidade} - ${item.estado}`));
            this.records.cidades = data.items;
            this.total = data.meta?.totalItems;
            this.totalPage = data.meta?.totalPages;
        },
        async orderedSupplier(client, city) {
            const path = 'suppliers/ordered';
            if (!city) {
                city = 0;
            }
            const service = new BaseService(`${path}/${client}/${city}/0`);
            const response = await service.findAll({});
            this.records.prestadores = [];
            this.records.prestadores = response.data;
        }
    }
};
</script>
<style></style>
