<template>
    <Dialog :style="{ width: '450px' }" class="p-fluid" :modal="true" :closable="false">
        <div class="field">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span style="font-weight: bold">{{ statusAgendamento.descricao }}</span>
            </div>
        </div>
        <template #footer>
            <Button label="Voltar" icon="pi pi-arrow-left" class="p-button-text" @click="$emit('onClose')" />
        </template>
    </Dialog>
</template>
<script>
import AreaMedicaService from '../../../../services/AreaMedicaService'

export default {
    props: ['agendamento'],
    data() {
        return {
            statusAgendamento: {}
        }
    },
    mounted() {
        this.$serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status/agendamento');
        this.load();
    },
    methods: {
        async load() {
            const agendamento = await this.$serviceAgendamentoStatus.findById(this.agendamento.id);
            this.statusAgendamento = agendamento.data;
        }
    }
}
</script>
<style>
</style>
