<template>
    <Dialog v-if="isOpen" v-model:visible="isOpen" modal :closable="false" :style="{ width: '450px' }" class="p-fluid" header="Filtros">
        <div class="field">
            <label>Filtro por Status</label>
            <MultiSelect
                v-model="filtrosExtras.status"
                :filter="true"
                :options="status"
                dataKey="id"
                optionLabel="descricao"
                placeholder="Selecione um status..."
            />
        </div>
        <div class="field mt-3">
            <label>Filtro por Tipo de Exame</label>
            <MultiSelect
                v-model="filtrosExtras.tipoExame"
                :filter="true"
                :options="exames"
                dataKey="id"
                optionLabel="descricao"
                placeholder="Selecione um Tipo de Exame..."
            />
        </div>
        <div class="field mt-3">
            <label>Filtro por Data</label>
            <div class="flex row w-12">
                <Calendar
                    id="range"
                    v-model="filtrosExtras.datas"
                    :manualInput="false"
                    class="w-11"
                    placeholder="Selecione as datas..."
                    selectionMode="range"
                />
                <Button v-tooltip.bottom="'Limpar range de datas'" class="w-1" icon="pi pi-undo" @click="limpar()" />
            </div>
        </div>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Cancelar" @click="isOpen = false" />
            <Button class="p-button-outlined p-button-success" icon="pi pi-check" label="Aplicar" @click="$emit('onAplicarFiltros', filtrosExtras)" />
        </template>
    </Dialog>
</template>

<script>
import StatusAgendamento from '../../../../enums/StatusAgendamento';
import AreaMedicaService from '../../../../services/AreaMedicaService';
import { getCompany } from '@/services/auth';

export default {
    name: 'FiltroDialog',
    emits: ['onAplicarFiltros'],
    data() {
        return {
            isOpen: false,
            $serviceStatus: null,
            $serviceTipoExames: null,
            enumStatusAgendamento: StatusAgendamento,
            status: [],
            exames: [],
            filtrosExtras: {}
        };
    },
    async mounted() {
        this.$serviceStatus = new AreaMedicaService('/status_agendamento');
        this.$serviceTipoExames = new AreaMedicaService('/tipo_exames');
        await this.carregarFiltros();
    },

    methods: {
        openDialog(statusList) {
            this.filtrosExtras.status = statusList;
            this.isOpen = true;
        },
        closeDialog() {
            this.isOpen = false;
        },
        limpar() {
            this.filtrosExtras.datas = null;
        },
        async carregarFiltros() {
            this.loadStatus();
            this.loadExames();
        },
        async loadStatus() {
            const { data } = await this.$serviceStatus.findAll({});
            this.status = data;
            const statusPendenciaPrestador = this.status.find((p) => p.id == this.enumStatusAgendamento.PENDENCIA_PRESTADOR);
            if (statusPendenciaPrestador) {
                const company = await getCompany();
                statusPendenciaPrestador.descricao = 'Pendencia ' + company.name;
            }
        },
        async loadExames() {
            const { data } = await this.$serviceTipoExames.findAll({});
            this.exames = data;
        }
    }
};
</script>

<style></style>
